<template>
  <div class="h-fill bg-app-green">
    <div class="bg-f bdr-circular position-fixed"
         style="right: -300px;top:-100px;width: 600px;height: 600px;opacity: 0.1;"></div>
    <div class="position-fixed w-fill t-0">
      <div class="h-80">
        <div class="pd-10 fs-14 text-strong">Anji</div>
      </div>
      <div class="text-align-center fs-30">革命性的学习工具</div>
      <div class="text-align-center mg-t-10">学外语、考研、高考的提分神器</div>
      <div class="justify-content-center mg-t-30">
        <div @click="onDownloadClick"
             class="bg-3 text-align-center lh-44 bdr-22 w-300" style="color: #FFF567">
          <!--          下载APP客户端-->
        </div>
      </div>
      <div class="text-align-center mg-t-100">
        <img src="/h5/static/images/image_a.png" class="w-fill"/>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "downloadApp",
  methods: {
    onDownloadClick() {
      //location.href = "https://file.ankichinas.cn/server/static/default.apk"
      //location.href = "https://file.ankichinas.cn/h5/static/default.apk"
    }
  }
}
</script>

<style scoped>

</style>
