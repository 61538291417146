<template>
  <div>
    <div v-if="!$parent.appInner" class="h-55">

    </div>
    <div class="pd-b-50">
      <van-tabs v-model="activeTab" color="#2E3DE6">
        <van-tab title="资源">
          <div v-if="orderList.length>0">
            <div v-for="order in orderList" class="mg-t-15 mg-l-10 mg-r-10 shadow-2 bdr-10 pd-10"
                 @click="onOrderClick(order)">
              <div class="fs-16 text-ellipsis-1">
                <span class="text-strong" style="vertical-align: middle;">{{ order.title }}</span>
              </div>
              <div class="justify-content-space-between mg-t-6 fs-12 align-items-center mg-r-10">
                <div class="fs-12 c-app-green text-align-right">
                  {{ order.levelName }} / {{ order.subjectName }}
                </div>
                <div class="c-app-orange">
                  <span class="c-9 fs-12 mg-r-20">{{ order.orderTime }}</span>
                  <span class="c-9 fs-12">已付：</span>
                  <template v-if="$parent.isIos && $parent.appInner">{{ order.realAmount / 100 }}学习币</template>
                  <template v-else>￥{{ order.realAmount / 100 }}</template>
                </div>
              </div>
            </div>
          </div>
          <div v-else class="text-align-center mg-t-100 c-8">
            暂无数据
          </div>
        </van-tab>
        <van-tab title="拼团">
          <div v-if="teamList.length>0">
            <div v-for="team in teamList" class="mg-t-15 mg-l-10 mg-r-10 shadow-2 bdr-10 pd-10"
                 @click="onTeamClick(team)">
              <div class="fs-16 text-ellipsis-1">
                <span class="text-strong" style="vertical-align: middle;">{{ team.title }}</span>
              </div>
              <div class="justify-content-space-between mg-t-6 fs-12 align-items-center mg-r-10">
                <div v-if="team.status == 9" class="fs-12  text-align-right c-app-green">
                  拼团成功
                  <span v-if="team.refundStatus==2" class="c-app-red">
                    (已退款)
                  </span>
                  <span v-if="team.refundStatus==3" class="c-app-red">
                    (退款失败)
                  </span>
                </div>
                <div v-else-if="team.status == -1" class="fs-12  text-align-right c-app-red">
                  拼团失败
                  <span v-if="team.refundStatus==2">
                    (已退款)
                  </span>
                  <span v-if="team.refundStatus==3">
                    (退款失败)
                  </span>
                </div>
                <div v-else class="fs-12  text-align-right c-9">
                  进度：<span class="c-app-green">{{ team.payCount }}</span> / <span
                    class="c-app-green">{{ team.users }}</span>
                  人
                </div>
                <div class="c-app-orange">
                  <span class="c-9">已付：</span>
                  <template v-if="$parent.isIos && $parent.appInner">{{ team.realAmount / 100 }}学习币</template>
                  <template v-else>￥{{ team.realAmount / 100 }}</template>
                </div>
              </div>
            </div>
          </div>
          <div v-else class="text-align-center mg-t-100 c-8">
            暂无数据
          </div>
        </van-tab>
        <van-tab title="待评价" :info="waitNum">
          <div v-if="waitScoreList.length>0">
            <div v-for="order in waitScoreList" class="mg-t-15 mg-l-10 mg-r-10 shadow-2 bdr-10 pd-10"
                 @click="onOrderClick(order)">
              <div class="fs-16 text-ellipsis-1">
                <span class="text-strong" style="vertical-align: middle;">{{ order.title }}</span>
              </div>
              <div class="justify-content-space-between mg-t-6 fs-12 align-items-center mg-r-10">
                <div class="fs-12 c-app-green text-align-right">
                  {{ order.levelName }} / {{ order.subjectName }}
                </div>
                <div class="c-app-orange">
                  <span class="c-9 fs-12 mg-r-20">{{ order.orderTime }}</span>
                  <span class="c-9 fs-12">已付：</span>
                  <template v-if="$parent.isIos && $parent.appInner">{{ order.realAmount / 100 }}学习币</template>
                  <template v-else>￥{{ order.realAmount / 100 }}</template>
                </div>
              </div>
            </div>
          </div>
          <div v-else class="text-align-center mg-t-100 c-8">
            暂无数据
          </div>
        </van-tab>
      </van-tabs>
    </div>
    <div v-if="!$parent.appInner" class="position-fixed t-0 w-fill align-items-center h-50 shadow-2 z-index-999 bg-f">
      <div class="flex-1">
        <van-icon name="arrow-left" class="pd-15" @click="$router.back()"/>
      </div>
      <div class="flex-1 text-align-center">
        已购
      </div>
      <div class="flex-1">

      </div>
    </div>
  </div>
</template>

<script>
import request from '../libs/api.request'

export default {
  name: "purchased",
  data() {
    return {
      activeTab: 0,
      orderList: [],
      teamList: [],
      waitScoreList: [],
      waitNum: '',
      test: ''
    }
  },
  methods: {
    getPurchasedList() {
      request.request({
        url: 'order/getPurchasedList',
      }).then((data) => {
        this.orderList = data.orderList;
        this.teamList = data.teamList;
        this.waitScoreList = data.waitScoreList;
        if (this.waitScoreList.length > 0) {
          this.waitNum = this.waitScoreList.length;
        }
      })
    },
    onOrderClick(order) {
      this.$router.push({path: '/card/' + order.cardId})
    },
    onTeamClick(team) {
      this.$router.push({path: '/team/' + team.teamId})
    }
  },
  mounted() {
    this.getPurchasedList();
    // this.test = (this.$parent.isIos ? '1111' : '222' ) + navigator.userAgent;
  }
}
</script>

<style>
.van-info {
  right: -5px !important;
  //background-color: #EC901D !important;
}

</style>
