<template>
  <div>
    <div v-if="user && user.phone">
      <div class="pd-20 text-align-center">
        <img src="/h5/static/images/logo.png" class="w-80 mg-t-50">
        <div class="text-strong fs-20 mg-t-20 mg-b-10">用户账号：{{ user.phone }}</div>
        <div class="c-a fs-12">注册时间：{{ user.createdAt }}</div>

      </div>
    </div>
    <div class="position-fixed b-0 l-0 r-0 fs-14 c-app-red">
      <div class="pd-20">
        <div>
          注销账户须知：<br>此功能为删除账号，不是退出登录！！<br>此功能为删除账号，不是退出登录！！<br>此功能为删除账号，不是退出登录！！<br>您购买的卡牌/会员/存储在云端的数据等所有信息将被永久删除无法找回。如您只希望退出登录而不销毁账号请返回【设置】页面点击'退出'操作
        </div>
        <div @click="onDestroyClick" class="bg-app-red c-f text-align-center bdr-20 pd-10 fs-18 mg-t-20">我要删除此账号
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import request from '../libs/api.request'

export default {
  name: "destroyUser",
  data() {
    return {
      user: {}
    }
  },
  methods: {
    mask() {
      try {
        akBridge.callHandler("akUserDestroySuccess");
      } catch (e) {

      }
      document.body.innerHTML = "<h1>您的账号已注销！</h1>";
    },
    onDestroyClick() {
      let message = '重要提醒：此功能不是退出登录！！您制作或购买的卡牌/会员等个人数据所有信息将被永久删除无法找回。如您只希望退出登录请返回【设置】页面点击\'退出\'操作';
      this.$dialog.confirm({
        message,
        confirmButtonText: '确认注销',
        cancelButtonText: '不注销',
      }).then(() => {
        this.$dialog.confirm({
          message: '要删除的账号是：' + this.user.phone + ' 请确认要操作的账号是否正确，如果不正确请重启APP。点击【确认删除】后此账号所有信息将永久删除且无法找回！',
          confirmButtonText: '确认删除',
          cancelButtonText: '再想想',
        }).then(() => {
          let u = this.$route.query.u;
          let p = this.$route.query.p;
          this.$toast.loading({message: '正在操作！', duration: 60000, overlay: true});
          request.request({
            url: '/user/confirmDestroy',
            data: {u, p},
            method: 'post'
          }).then((data) => {
            this.mask();
          }).catch((e) => {
            this.$toast(e.message)
          });
        });
      });
    }
  },
  mounted() {
    let u = this.$route.query.u;
    let p = this.$route.query.p;
    request.request({
      url: '/user/checkForDestroy',
      data: {u, p},
      method: 'post'
    }).then((data) => {
      this.user = data;
    }).catch((e) => {
      this.$toast(e.message)
    });
  }
}
</script>

<style scoped>

</style>
