<template>
  <div class="h-fill bg-e">
    <div class="position-fixed t-0 w-fill bg-f z-index-999">
      <div class="justify-content-space-between align-items-center pd-l-15 pd-r-15">
<!--        <div class="flex-1" @click="onMenuClick">
          <div class="bg-2 w-20 bdr-2" style="height: 2px"></div>
          <div class="bg-2 w-20 bdr-2 mg-t-2" style="height: 2px"></div>
          <div class="bg-2 w-20 bdr-2 mg-t-2" style="height: 2px"></div>
        </div>-->
        <div class="" style="width: 90%">
          <form action="">
            <van-search v-model="keyword" placeholder="搜索资源" shape="round" @search="onSearch"/>
          </form>
        </div>
<!--        <a v-if="!$parent.appInner" @click="onDownloadAppClick" target="_blank"
           class="flex-2 fs-14 mg-r-10 row-reverse c-app-green">
          下载APP
        </a>-->
        <div class="flex-1 row-reverse">
<!--          <div class="mg-l-10 pd-l-5 pd-r-5">
            <van-icon class="fs-20" @click="onPublishClick" name="plus"/>
          </div>-->
          <div class="position-relative pd-l-5 pd-r-5">
            <van-icon class="fs-20" @click="onPurchasedClick" name="bag-o"/>
            <div v-if="orderMessageCount" class="w-6 h-6 bdr-circular bg-app-red position-absolute t-0 r-0"></div>
          </div>
        </div>
      </div>
      <div>
        <van-tabs class="text-strong" color="#2E3DE6" title-active-color="#2E3DE6" v-model="levelId"
                  @click="onLevelChange">
          <van-tab v-for="item in levelList" :title="item.text" :name="item.value"></van-tab>
        </van-tabs>
      </div>
    </div>
<!--    <div class="left-menu position-fixed bdr-10 l-0 t-110 b-0 w-80 fs-12 text-strong" style="overflow-y: scroll">
      <div @click="onSubjectClick(item)" v-for="item in subjectList"
           class="pd-t-10 pd-b-10"
           :class="subjectId == item.subjectId?'bg-e c-2':'c-9'">
        <div class="text-ellipsis-1 text-align-center ">{{ item.subjectName }}</div>
      </div>
    </div>-->
    <div class="pd-t-110 pd-l-10 pd-r-10 bg-e">
      <div v-for="item,index in cardList" class="pd-b-10 bg-e">
        <card-item class="bg-f bdr-6" :card="item"></card-item>
      </div>
      <div v-if="isNoMore" class="pd-25 fs-12 c-9 text-align-center">没有更多了</div>
    </div>
    <van-popup class="h-fill" v-model="showPanel" position="left">
      <div class="w-220 bg-f h-fill">
        <div class="h-140 bg-app-green position-relative">
        </div>
        <div v-if="$parent.loginUser" class="pd-20 align-items-center">
          <img width="45" height="45" :src="$parent.loginUser.avatar" class="bg-e8e bdr-circular mg-r-10"/>
          {{ $parent.loginUser.nickname }}
        </div>
        <div v-else class="align-items-center pd-8">
          <van-icon name="/h5/static/images/menu-login.png" class="mg-10"/>
          <div @click="onLoginClick">登录</div>
        </div>
        <div class="align-items-center pd-8">
          <van-icon name="/h5/static/images/menu-my-resource.png" class="mg-10"/>
          <div @click="onMyResourceClick">我发布的</div>
        </div>
        <div class="align-items-center pd-8">
          <van-icon name="/h5/static/images/menu-settings.png" class="mg-10"/>
          <div @click="onSettingsClick">设置中心</div>
        </div>
        <div class="align-items-center pd-8">
          <van-icon name="/h5/static/images/menu-my-resource.png" class="mg-10"/>
          <div @click="onSetUserLabelClick">内容偏好</div>
        </div>
        <div class="align-items-center pd-8">
          <van-icon name="/h5/static/images/menu-my-resource.png" class="mg-10"/>
          <div @click="onUserCardBrowsingHistoryClick">浏览记录</div>
        </div>
        <div v-if="$parent.loginUser && !$parent.appInner" class="align-items-center pd-8">
          <van-icon name="/h5/static/images/menu-logout.png" class="mg-10"/>
          <div @click="onLogoutClick">退出</div>
        </div>
        <div class="pd-15 c-9 fs-12" v-if="$parent.appInner">
          <a @click="getAppVersion">APP版本:{{ $parent.appVersion }}</a>
        </div>
        <div class="pd-15 c-9 fs-12">
          资源网版本:{{ conf.version }}
        </div>
      </div>
    </van-popup>
    <van-dialog v-model="showPublishNotice" title="提示" show-cancel-button confirmButtonText="确定"
                @confirm="onPublishNoticeConfirm">
      <div class="mg-20">
        <div class="text-align-center">
          抱歉移动端暂未适配，请点击'确定'复制链接然后用电脑浏览器打开发布卡牌
        </div>
      </div>
    </van-dialog>
    <user-label ref="myUserLabel"></user-label>
  </div>
</template>

<script>
import cardItem from '../components/cardItem'
import userLabel from '../components/userLabel';
import request from '../libs/api.request'
import storage from "../libs/storage";
import conf from "../libs/conf";

export default {
  name: "index",
  components: {
    cardItem,
    userLabel,
  },
  data() {
    return {
      keyword: '',
      conf: conf,
      showPanel: false,
      showPublishNotice: false,
      levelId: 0,
      subjectId: 0,
      sortType: 0,
      sortList: [
        {text: '综合排序', value: 0},
        {text: '下载量', value: 1},
        {text: '评分', value: 2},
        {text: '发布时间', value: 3},
      ],
      cardList: [],
      currentPage: 1,
      orderMessageCount: 0,
      pageSize: 20,
      isNoMore: false,//是否没有更多了
      isLoadData: false,//是否正在加载数据
      subjectList: [{text: '全部', value: 0}],
      levelList: [
        {value: 0, text: '热门'},
        {value: 7, text: '考证/考公/职考'},
        {value: 6, text: '考研'},
        {value: 5, text: '大学'},
        {value: 4, text: '高中'},
        {value: 3, text: '初中'},
        {value: 2, text: '小学'},
        {value: 1, text: '学前'},
        {value: 99, text: '其他'},
        {value: 100, text: '模板'},
      ]
    }
  },
  methods: {
    onMenuClick() {
      this.showPanel = true;
    },
    getAppVersion() {
      if (akBridge) {
        akBridge.callHandler("getAppVersion", null, (version) => {
          if (version) {
            this.$dialog.alert({
              message: '获取到版本号：' + version,
            });
          }
        });
      } else {
        let version = AnkiDroidJS.getAppVersion();
        if (version) {
          this.$dialog.alert({
            message: '获取到版本号：' + version,
          });
        } else {
          this.$dialog.alert({
            message: '没有获取到版本号',
          });
        }
      }
    },
    onNoticeClick() {
      this.$router.push({path: '/course'})
    },
    getData() {
      this.isLoadData = true;
      request.request({
        url: '/card/recommend',
        params: {
          page: this.currentPage,
          size: this.pageSize,
          subjectId: this.subjectId,
          levelId: this.levelId,
          sortType: this.sortType,
          keyword: this.keyword,
          appVersion: this.$parent.appVersion
        }
      }).then((data) => {
        this.isLoadData = false;
        this.subjectList = data.subjectList;
        this.isNoMore = data.list.length < this.pageSize;
        if (this.currentPage == 1) {
          this.cardList = data.list;
        } else {
          this.cardList = this.cardList.concat(data.list);
        }
      }).catch(() => {
        this.isLoadData = false;
      })
    },
    loadMore() {
      if (this.isNoMore || this.isLoadData) {
        console.log(this.isNoMore);
        console.log(this.isLoadData);
        return;
      }

      this.currentPage++;
      this.getData();
    },
    onLevelChange() {
      this.currentPage = 1;
      this.subjectId = 0;
      document.body.scrollTop = document.documentElement.scrollTop = 0;
      this.getData();
    },
    onSubjectClick(item) {
      this.subjectId = item.subjectId;
      this.currentPage = 1;
      document.body.scrollTop = document.documentElement.scrollTop = 0;
      this.getData();
    },
    onSortTypeChange() {
      this.currentPage = 1;
      this.getData();
    },
    onSearch() {
      this.currentPage = 1;
      this.levelId = 0;
      this.subjectId = 0;
      this.sortType = 0;
      //回到第一页距离
      document.documentElement.scrollTop = 0;
      this.getData();
    },
    onDownloadClick() {
      location.href = 'https://www.ankichinas.cn';
    },
    onPublishNoticeConfirm() {
      this.$parent.execCopy('https://' + location.host + '?app-token=' + this.$cookies.get('authToken'), '链接已复制，请使用浏览器打开');
    },
    onPublishClick() {
      if (this.$parent.loginUser == null) {
        this.$parent.login();
        return;
      }

      // if (true) {
      if (this.$parent.appInner && this.$parent.isAndroid) {
        this.showPublishNotice = true;
      } else {
        location.href = '/card/edit/0'
      }
    },
    onLoginClick() {
      this.$parent.login();
    },
    onCourseClick() {
      this.$router.push({path: '/course'})
    },
    onMyResourceClick() {
      if (this.$parent.loginUser) {
        location.href = 'https://file.ankichinas.cn/my?op=resource&t=' + (new Date()).getTime();
      } else {
        this.$parent.login();
      }
    },
    onSettingsClick() {
      if (this.$parent.loginUser) {
        location.href = '/my?op=settings';
      } else {
        this.$parent.login();
      }
    },
    onSetUserLabelClick() {
      if (this.$parent.loginUser) {
        this.$refs.myUserLabel.onChangeShow();
      } else {
        this.$parent.login();
      }
    },
    onUserCardBrowsingHistoryClick() {
      if (this.$parent.loginUser) {
        this.$router.push({path: '/userCardBrowsingHistory/'})
      } else {
        this.$parent.login();
      }
    },
    onLogoutClick() {
      this.$dialog.confirm({message: '是否退出登录？'}).then(() => {
        this.$cookies.remove('authToken');
        storage.setUserInfo(null);
        location.reload();
      })
    },
    //滚动条在Y轴上的滚动距离
    getScrollTop() {
      var scrollTop = 0, bodyScrollTop = 0, documentScrollTop = 0;
      if (document.body) {
        bodyScrollTop = document.body.scrollTop;
      }
      if (document.documentElement) {
        documentScrollTop = document.documentElement.scrollTop;
      }
      scrollTop = (bodyScrollTop - documentScrollTop > 0) ? bodyScrollTop : documentScrollTop;
      return scrollTop;
    },
    //文档的总高度
    getScrollHeight() {
      var scrollHeight = 0, bodyScrollHeight = 0, documentScrollHeight = 0;
      if (document.body) {
        bodyScrollHeight = document.body.scrollHeight;
      }
      if (document.documentElement) {
        documentScrollHeight = document.documentElement.scrollHeight;
      }
      scrollHeight = (bodyScrollHeight - documentScrollHeight > 0) ? bodyScrollHeight : documentScrollHeight;
      return scrollHeight;
    },
    //浏览器视口的高度
    getWindowHeight() {
      var windowHeight = 0;
      if (document.compatMode == "CSS1Compat") {
        windowHeight = document.documentElement.clientHeight;
      } else {
        windowHeight = document.body.clientHeight;
      }
      return windowHeight;
    },
    onDownloadAppClick() {
      if (this.$parent.isIos) {
        location.href = "https://mp.weixin.qq.com/s?__biz=MzU2OTg0NTAxMw==&mid=2247483671&idx=1&sn=f041df5e0f187f9c3de0d10ecac05d89&chksm=fcf9356dcb8ebc7bde77daa3c70830400b07b8859f25b62ae957e16df718d537592bf7f60c10#rd";
      } else {
        location.href = "https://ankimemory.com";
      }
    },
    onPurchasedClick() {
      this.$router.push({path: '/purchased'})
    }
  },
  created() {
    this.getData();
    window.onscroll = () => {
      // this.$toast(parseInt(this.getScrollTop()) + this.getWindowHeight() + ' ' + this.getScrollHeight());
      if (this.getScrollTop() + this.getWindowHeight() >= this.getScrollHeight() - 150) {
        this.loadMore();
      }
    }
    setTimeout(() => {
      if (this.$parent.loginUser) {
        request.request({
          url: 'order/orderMessageCount',
        }).then(data => {
          this.orderMessageCount = data.orderMessageCount;
        })
      }
    }, 1000)

    let copyrightNotice = localStorage.getItem("copyrightNotice");
    if (copyrightNotice !== 'read') {
      this.$dialog.alert({
        message: '欢迎来到Anki资源网\n\nAnki资源网的内容、资料、讨论等信息均由用户上传及维护，<b>作为促进学习资源交流的公益属性平台，不参与交易的抽成</b>，如您将与其他用户发生交易请谨慎权衡。尽管我们已尽到最大的努力，但客观上无法避免用户的信息完全合规，如您认为用户上传的信息侵犯了您的知识产权或其他合法权益，请您通过用户协议中的材料要求、联系方式通知我们。',
        'confirmButtonText': '我知道了'
      }).then(() => {
        localStorage.setItem("copyrightNotice", "read");
      })
    }
  },
  mounted() {
  },
  beforeDestroy() {
    window.onscroll = null;
  }
}

</script>

<style scoped>
.left-menu {
  background: white;
}

.left-menu::-webkit-scrollbar {
  display: none
}

.van-tab--active {
  font-weight: 900;
}
</style>
