<template>
  <div class="display-flex justify-content-center w-fill">
    <div class="h-fill bg-e8e" style="max-width: 400px">
      <div class="position-relative pd-0 mg-0">
        <img src="/h5/static/images/vip_head_bg.png?v=1" class="w-fill" style="margin-top: -2px"/>
        <div class="position-absolute w-fill pd-10" style="top:0;background-color: rgba(255,255,255,0.8)">
          <div class="justify-content-space-between align-items-center">
            <div class="align-items-center" @click="onUserClick">
              <img :src="ambassador.avatar" class="w-30 h-30  bdr-circular"/>
              <div>
                <div class="fs-14 mg-l-10">{{ ambassador.nickname }}</div>
                <div class="fs-12 c-9 mg-l-10">邀请您开通Anji超级学霸</div>
              </div>
            </div>
          </div>
        </div>
        <div class="position-absolute w-fill" style="top:80px">
          <div class="mg-l-20 mg-r-20 pd-15"
               style="background-image: url('/h5/static/images/vip_head_content_bg.png');background-size: 100% 100%">
            <div class="display-flex">
              <img src="/h5/static/images/vip_icon.png" class="w-34 h-36 mg-r-10"/>
              <div class="w-fill">
                <div v-if="isVip" class="justify-content-space-between align-items-center pd-b-10"
                     style="border-bottom: rgba(255,255,255,.2) solid 1px;">
                  <div class="c-f">
                    <div class="fs-16">你成为超级学霸已{{ vipDay }}天</div>
                    <div class="fs-12">{{ isForever ? '有效期至永久' : expireDay + '过期' }}</div>
                  </div>
                  <div v-if="!isForever" @click="onBuyClick" class="bdr-16 fs-14 lh-24 w-66 text-align-center"
                       style="background: linear-gradient(221deg, #FFF1E0 0%, #FFE5C6 43%, #FFC073 100%);">
                    续费
                  </div>
                </div>
                <div v-else-if="$parent.loginUser" class="justify-content-space-between pd-b-25"
                     style="border-bottom: rgba(255,255,255,.2) solid 1px;">
                  <div class="c-f">
                    <div class="fs-16">未开通超级学霸</div>
                    <div class="fs-16">{{ $parent.loginUser.phone }}</div>
                  </div>
                  <div @click="onBuyClick" class="bdr-16 fs-14 lh-24 h-24 w-66 text-align-center"
                       style="background: linear-gradient(221deg, #FFF1E0 0%, #FFE5C6 43%, #FFC073 100%);">
                    立即开通
                  </div>
                </div>
                <div v-else class="justify-content-space-between pd-b-25"
                     style="border-bottom: rgba(255,255,255,.2) solid 1px;">
                  <div class="c-f">
                    <div class="fs-16">您尚未登录</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="pd-15 bg-e8e fs-12" v-if="!isForever">
            <div class="h-50 justify-content-center align-items-center"
                 style="background: linear-gradient(233deg, #FFECD6 0%, #FFD8A9 100%);border-radius: 10px 10px 0px 0px;">
              <img src="/h5/static/images/vip_title1.png" class="w-130"/>
            </div>
            <div class="bg-f pd-20" style="border-radius: 0px 0px 10px 10px;">
              <!--<div v-if="pkg.length>1 && pkg[0].activityEndAt" class="fs-16 c-app-orange">{{ pkg[0].activityEndAt }}</div>-->
              <div class="display-flex text-align-center pd-t-10 pd-b-10">
                <div v-for="item,index in pkg"
                     class="flex-1 border-3 bdr-10 shadow-2 align-items-center justify-content-center h-110 mg-l-5 mg-r-5 position-relative"
                     :class="index == selectPkg?'bdc-app-orange':'bdc-f'" @click="onPkgClick(index)">
                  <div class="column align-items-center">
                    <div class="c-app-orange fs-16 text-strong">￥ <span class="fs-22">{{ item.money }}</span></div>
                    <div v-if="item.originMoney" class=" text-delete c-9">
                      原价￥<span class="fs-12">{{ item.originMoney }}</span></div>
                    <div>{{ item.desc }}</div>
                  </div>
                  <div v-if="item.originMoney"
                       class="position-absolute t-0 fs-12 bg-app-orange c-f pd-2 pd-l-5 pd-r-5"
                       style="right: -3px;top:-3px;border-bottom-left-radius: 10px;border-top-right-radius: 10px"
                  >{{ item.desc2 }}
                  </div>
                </div>
              </div>
            </div>
            <div v-if="from=='card'" class="mg-t-15 mg-l-10 mg-r-10">
              付费卡牌由用户上传发布，不属于超级学霸特权，仍需另外付款购买！
            </div>
          </div>
          <div class="pd-l-20 bg-e8e pd-r-20">
            <div class="c-f h-50 fs-18 justify-content-center align-items-center pd-t-4"
                 style="background-image: url('/h5/static/images/vip_title2_bg.png');background-size: 100% 100%">
              <img src="/h5/static/images/vip_title_left.png" class="w-12"/>
              <div class="mg-l-5 mg-r-5">超级学霸权益说明</div>
              <img src="/h5/static/images/vip_title_right.png" class="w-12"/>
            </div>
            <div class="bg-f pd-15" style="border-radius: 0px 0px 10px 10px;">
              <van-row>
                <van-col span="12">
                  <div class="mg-5 shadow-2 bdr-10 pd-8 h-100">
                    <img src="/h5/static/images/vip_dsbtb.png" class="w-34"/>
                    <div class="fs-14 text-strong">跨设备同步数据</div>
                    <div class="fs-10 c-9 text-ellipsis-2">全平台网页版/平板/IOS设备同步学习资料和进度</div>
                  </div>
                </van-col>
                <van-col span="12">
                  <div class="mg-5 shadow-2 bdr-10 pd-8 h-100">
                    <img src="/h5/static/images/vip_zdlx.png" class="w-32"/>
                    <div class="fs-14 text-strong">主动练习</div>
                    <div class="fs-10 c-9 text-ellipsis-2">实现有针对性的练习，巩固薄弱知识点</div>
                  </div>
                </van-col>
                <van-col span="12">
                  <div class="mg-5 shadow-2 bdr-10 pd-8 h-100">
                    <img src="/h5/static/images/vip_yyld.png" class="w-32"/>
                    <div class="fs-14 text-strong">语音朗读功能</div>
                    <div class="fs-10 c-9 text-ellipsis-2">机器朗读卡牌上的单词、文字</div>
                  </div>
                </van-col>
                <van-col span="12">
                  <div class="mg-5 shadow-2 bdr-10 pd-8 h-100">
                    <img src="/h5/static/images/vip_yjwk.png" class="w-32"/>
                    <div class="fs-14 text-strong">一键挖空功能</div>
                    <div class="fs-10 c-9 text-ellipsis-2">高效制作填空题卡牌</div>
                  </div>
                </van-col>
                <van-col span="12">
                  <div class="mg-5 shadow-2 bdr-10 pd-8 h-100">
                    <img src="/h5/static/images/vip_xzt.png" class="w-32"/>
                    <div class="fs-14 text-strong">选择题模版</div>
                    <div class="fs-10 c-9 text-ellipsis-2">兼容多平台，更加美观好用</div>
                  </div>
                </van-col>
                <van-col span="12">
                  <div class="mg-5 shadow-2 bdr-10 pd-8 h-100">
                    <img src="/h5/static/images/vip_plzk.png" class="w-32"/>
                    <div class="fs-14 text-strong">批量制卡</div>
                    <div class="fs-10 c-9 text-ellipsis-2">简单高效实现批量制卡</div>
                  </div>
                </van-col>
                <van-col span="12">
                  <div class="mg-5 shadow-2 bdr-10 pd-8 h-100">
                    <img src="/h5/static/images/vip_kszkzs.png" class="w-32"/>
                    <div class="fs-14 text-strong">一键图片挖空</div>
                    <div class="fs-10 c-9 text-ellipsis-2">快速制作图片挖空卡</div>
                  </div>
                </van-col>
              </van-row>
              `
            </div>
          </div>

          <div class="pd-20 bg-e8e">
            <div class="c-f h-50 fs-18 justify-content-center align-items-center pd-t-4"
                 style="background-image: url('/h5/static/images/vip_title3_bg.png');background-size: 100% 100%">
              <img src="/h5/static/images/vip_title_left.png" class="w-12"/>
              <div class="mg-l-5 mg-r-5">开发中的超能力</div>
              <img src="/h5/static/images/vip_title_right.png" class="w-12"/>
            </div>
            <div class="bg-f pd-15" style="border-radius: 0px 0px 10px 10px;">
              <div class="fs-12 mg-b-10">以下新能力正在开发中，近期将陆续上线！</div>
              <van-row>
                <van-col span="12">
                  <div class="mg-5 shadow-2 bdr-10 pd-8 h-100">
                    <img src="/h5/static/images/vip_kpwbbjq.png" class="w-32"/>
                    <div class="fs-14 text-strong">可视化富文本编辑器</div>
                    <div class="fs-10 c-9 text-ellipsis-2">更直观、更强大的编辑器</div>
                  </div>
                </van-col>

                <van-col span="12">
                  <div class="mg-5 shadow-2 bdr-10 pd-8 h-100">
                    <img src="/h5/static/images/vip_sjwk.png" class="w-32"/>
                    <div class="fs-14 text-strong">随机挖空功能</div>
                    <div class="fs-10 c-9 text-ellipsis-2">背书神器，通过主动检索记忆实现高效背书</div>
                  </div>
                </van-col>

                <van-col span="12">
                  <div class="mg-5 shadow-2 bdr-10 pd-8 h-100">
                    <img src="/h5/static/images/vip_cjdc.png" class="w-32"/>
                    <div class="fs-14 text-strong">超级单词模版</div>
                    <div class="fs-10 c-9 text-ellipsis-2">只需输入单词，自动添加音标、发声、翻译</div>
                  </div>
                </van-col>
              </van-row>
            </div>
          </div>
        </div>
        <div v-if="$parent.loginUser">
          <div class="position-fixed l-0 b-0 w-fill bg-f display-flex justify-content-center" v-if="!isForever">
            <div @click="onBuyClick"
                 style="max-width: 400px"
                 class="mg-l-20 mg-r-20 w-fill mg-t-6 mg-b-6 pd-10 bg-app-orange c-f text-align-center lh-30 fs-20 bdr-25">
              {{ isVip ? '开通超级学霸' : '续费超级学霸' }}
              <div class="flex-2 text-align-right fs-12 position-absolute t-16 r-30">
                <span v-if="pkg.length > 0">￥{{ pkg[selectPkg].money }}{{ pkg[selectPkg].desc }}</span>
              </div>
            </div>
          </div>
        </div>
        <div v-else>
          <div class="position-fixed l-0 b-0 w-fill bg-f display-flex justify-content-center">
            <div @click="onLoginClick"
                 style="max-width: 400px"
                 class="mg-l-20 mg-r-20 w-fill mg-t-6 mg-b-6 pd-10 bg-app-orange c-f text-align-center lh-30 fs-20 bdr-25">
              立即登录
            </div>
          </div>
        </div>
      </div>

      <van-dialog v-model="showPayResult" title="支付提示" show-cancel-button confirmButtonText="已支付"
                  cancelButtonText="未支付" @confirm="onPayConfirm" @cancel="onPayCancel">
        <div class="mg-20">
          <div class="text-align-center">是否已支付完成？</div>
          <div v-if="$parent.appInner && copyPayUrl" class="c-app-red fs-14 mg-t-20">调起支付中，若无反应请使用手机浏览器支付，<span
              class="c-app-blue"
              @click="onCopyPayUrlClick">点我复制链接</span>
          </div>
        </div>
      </van-dialog>

      <van-action-sheet
          v-model="showPayOptions"
          cancel-text="取消"
          close-on-click-action>
        <div class="bg-f pd-20 fs-18">
          <div class="c-a fs-14 text-align-center mg-b-30">请选择支付方式</div>
          <div @click="onPaySelectClick('alipay_h5')" class="justify-content-center align-items-center pd-10">
            <img src="/h5/static/images/alipay.png" class="w-20">
            <div class="w-120 text-align-center">支付宝</div>
          </div>
          <div class="h-1 bg-f3f mg-t-15 mg-b-10"></div>
          <!--<div v-if="!morePayWay" @click="morePayWay = true"-->
          <!--class="justify-content-center align-items-center pd-10 mg-t-10">-->
          <!--<div class="w-200 text-align-center c-6 fs-12">点此使用其他支付方式&gt;&gt;</div>-->
          <!--</div>-->
          <!--<template v-else>-->
          <div @click="onPaySelectClick('wechat')" class="justify-content-center align-items-center pd-10 mg-t-10">
            <img src="/h5/static/images/wechatpay.png" class="w-20">
            <div class="w-120 text-align-center">微信支付</div>
          </div>
          <!--</template>-->
        </div>
      </van-action-sheet>
    </div>
  </div>
</template>

<script>
import request from '../libs/api.request'
import util from '../libs/util'

export default {
  name: "vip",
  data() {
    return {
      ambassadorCode: this.$route.params.code,
      ambassadorUserId: null,
      morePayWay: false,
      useSize: '-',
      totalSize: '-',
      pkg: [],
      isVip: false,
      copyPayUrl: '',
      vipDay: '',
      expireDay: '',
      selectPkg: 0,
      showPayResult: false,
      showPayOptions: false,
      ordering: false,
      remark: '',
      isForever: false,
      from: this.$route.query.from,
      ambassador: {}
    }
  },
  methods: {
    onUserClick() {
      location.href = '/userCenter/' + this.ambassador.userId;
    },
    onPaySelectClick(payType) {
      if (this.ordering) {
        return;
      }

      if (payType.indexOf('ali') > -1 && util.inWechat()) {
        this.$parent.showCommonMask("微信内不支持支付宝", "请打开外部浏览器进行支付");
        return;
      }

      if (payType.indexOf("wechat") > -1) {
        if (util.inWechatPC()) {
          this.$dialog.alert({message: "请使用手机版微信打开链接支付"});
          return;
        }
        payType = util.inWechat() ? 'wechat_gzh' : 'wechat_h5'
      }

      this.ordering = true;
      this.$toast.loading({message: '下单中', duration: 8000, overlay: true});
      request.request({
        url: '/user/vipOrder',
        data: {
          id: this.pkg[this.selectPkg].id,
          ambassadorCode: this.ambassadorCode,
          returnUrl: location.href,
          payType: payType,
          remark: this.remark,
          wxOpenId: this.$parent.wxOpenId
        },
        method: 'post'
      }).then((data) => {
        this.$toast.clear();
        if (util.inWechat()) {
          WeixinJSBridge.invoke('getBrandWCPayRequest', data.payData, (res) => {
            if (res.err_msg === "get_brand_wcpay_request:ok") {
              this.showPayOptions = false;
              this.$toast.loading({message: '正在查询支付结果', duration: 60000, overlay: true});
              setTimeout(() => {
                location.reload();
              }, 3000);
            }
          });
        } else {
          this.ordering = false;
          this.copyPayUrl = data.copyPayUrl;
          setTimeout(() => {
            this.showPayResult = true;
            this.showPayOptions = false;
          }, 800);
          location.assign(data.payUrl);
        }
      }).catch((err) => {
        this.$toast.clear();
        this.ordering = false;
        this.$toast(err.message)
      })
    },
    onPayConfirm() {
      let href = location.href;
      this.$toast.loading({message: '', duration: 8000, overlay: true});
      setTimeout(() => {
        location.replace(href.replace(new RegExp("h5pay_redirect=", "gm"), 'h5pay_redirected='));
      }, 1500);
    },
    onPayCancel() {
      let href = location.href;
      location.replace(href.replace(new RegExp("h5pay_redirect=", "gm"), 'h5pay_redirected='));
    },
    onCopyPayUrlClick() {
      this.$parent.execCopy(this.copyPayUrl, '链接已复制请前往手机浏览器粘贴支付');
    },
    onPkgClick(index) {
      this.selectPkg = index;
    },
    onBuyClick() {
      if (!this.$parent.loginUser) {
        this.$toast('请先登录');
        return;
      }

      this.showPayOptions = true;
    },
    onLoginClick() {
      this.$parent.login();
    }
  },
  mounted() {
    this.remark = this.$route.query['remark'] || '';
    this.$toast.loading({message: '', duration: 60000, overlay: true});
    if (this.remark === 'coupon') {
      setTimeout(() => {
        location.replace("https://file.jiushanteam.com/h5/vip?remark=formiosapp")
      }, 350);
      return;
    }

    request.request({
      url: 'user/vipInfo',
      params: {appVersion: this.$parent.appVersion, ios: 0, ambassadorCode: this.ambassadorCode}
    }).then(data => {
      this.pkg = data.pkg;
      this.ambassador = data.ambassador;
      setTimeout(() => {
        this.$toast.clear();
      }, 500);
      if (this.$parent.loginUser) {
        this.isVip = data.isVip;
        this.totalSize = data.totalSize;
        this.useSize = data.useSize;
        this.vipDay = data.vipDay;
        this.expireDay = data.expireDay;
        this.isForever = data.isForever;
      }
    }).catch((err, code) => {
      this.$toast(err)
    });

    if (this.$route.query['h5pay_redirect'] === 'yes') {
      setTimeout(() => {
        this.showPayResult = true;
      }, 100)
    }
  }
}
</script>

<style scoped>

</style>
