<template>
  <div class="pd-15">
    <div v-if="list === false"></div>
    <div v-else-if="list.length > 0" class="pd-b-50">
      <div v-for="item in list">
        <div class="text-strong">【{{ selected[item.selected] }}】{{ item.message }}</div>
        <div class="fs-14 pd-5">
          <div class="fs-12 c-9">提交日期：{{ item.createdAt }}</div>
          <div>
            <span v-if="item.replyMessage">平台回复：{{ item.replyMessage }}</span>
            <span v-else class="c-9">已提交到平台，您可以晚点再来查看回复结果</span>
          </div>
          <hr>
        </div>
      </div>
    </div>
    <div v-else class="text-align-center">暂无记录</div>
  </div>
</template>

<script>

import request from '../libs/api.request'

export default {
  name: "report",
  data() {
    return {
      list: false,
      selected: ['卡牌盗卖', '质量问题', '显示问题', '其他']
    }
  },
  mounted() {
    request.request({
      url: '/card/userReportList'
    }).then((data) => {
      this.list = data.list;
    }).catch(e => {
      this.$toast(e);
    });
  }
}
</script>

<style scoped>

</style>
