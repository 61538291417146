<template>
  <div id="app">
    <keep-alive>
      <router-view v-if="$route.meta.KeepAlive"/>
    </keep-alive>

    <router-view v-if="!$route.meta.KeepAlive"/>
    <!--<router-view></router-view>-->
    <van-popup v-model="showLoginDialog">
      <div class="w-300 position-relative h-350 bg-f bdr-15">
        <div class="pd-20 text-strong text-align-center fs-18">登录/注册</div>
        <div class="pd-l-20 pd-r-20">
          <div>
            <van-field v-model="loginForm.phone" type="number" maxlength="11" placeholder="手机号"
                       class="bg-f3f "></van-field>
          </div>
          <div class="mg-t-20 position-relative">
            <van-field v-model="loginForm.code" type="number" maxlength="6" placeholder="验证码"
                       class="bg-f3f"></van-field>
            <span @click="onGetCodeClick" class="position-absolute t-0 r-0 pd-15 fs-12 c-app-blue">{{getSMStxt}}</span>
          </div>
          <div @click="submitLoginForm" class="bg-app-blue text-align-center lh-44 c-f bdr-22 mg-t-20">登录</div>
        </div>
        <div class="mg-t-20 fs-14 justify-content-center align-items-center">
          <van-checkbox class="mg-r-5" v-model="agree"></van-checkbox>
          已阅读并同意<a href="https://api.jiushanteam.com/server/index/ajagreement?c=Anji" class="c-app-green cursor-pointer">用户协议</a>和<a
            class="c-app-green cursor-pointer" href="https://api.jiushanteam.com/server/index/ajprivacy?c=Anji">隐私政策</a>
        </div>
        <div class="h-30 loginDialogFooter position-absolute b-30 w-fill"></div>
      </div>
    </van-popup>

    <div @click="commonMask = null" v-if="commonMask" class="position-fixed t-0 b-0 w-fill"
         style="background-color: rgba(0,0,0,0.8);z-index: 99999">
      <div class="text-align-right pd-10">
        <img src="/h5/static/images/course/arrow.png" width="100">
      </div>
      <div class="text-align-center pd-10 c-f">
        <div>{{commonMask.title}}</div>
        <div>{{commonMask.desc}}</div>
      </div>
    </div>

    <div @click="wechatShareMask = false" v-if="wechatShareMask" class="position-fixed t-0 b-0 w-fill"
         style="background-color: rgba(0,0,0,0.8);z-index: 99999">
      <div class="text-align-right pd-10">
        <img src="/h5/static/images/course/arrow.png" width="100">
      </div>
      <div class="text-align-center pd-10 c-f">
        <div>请点击右上角菜单分享给好友</div>
      </div>
    </div>

    <div class="position-fixed" id="copy-content" style="bottom: -999px;opacity: 0">{{copyContent}}</div>
    <div v-if="debugMsg" id="debug-content" class="bg-1 c-f position-fixed t-0 l-0 r-0"
         style="z-index: 999999;max-height: 400px">
      <div v-for="item in debugMsg">{{item}}</div>
    </div>
  </div>
</template>

<script>
import request from './libs/api.request'
import util from './libs/util'
import storage from './libs/storage'

export default {
  name: 'App',
  components: {},
  data() {
    return {
      agree: false,
      authToken: '',
      copyContent: '',
      showLoginDialog: false,
      loginUser: null,
      appInner: false,
      getSMStxt: '获取验证码',
      loginForm: {
        phone: '',
        code: '',
        fromH5: 1
      },
      commonMask: null,
      wechatShareMask: false,
      isAndroid: false,
      isIos: false,
      debugMsg:
        [],
      appVersion:
        '0',
      wxOpenId:
        ''
    }
  },
  methods: {
    debug(msg) {
      if (typeof (msg) != 'string')
        msg = JSON.stringify(msg);
      process.env.NODE_ENV === "development" && this.debugMsg.push(msg)
    },
    login() {
      if (this.appInner && this.isIos) {
        if (akBridge) {
          akBridge.callHandler("akUserLogin");
        } else {
          this.$toast('调起登录异常');
        }
      } else {
        this.showLoginDialog = true;
      }
    },
    execCopy(content, successMessage) {
      this.copyContent = content;
      setTimeout(() => {
        const range = document.createRange();
        range.selectNode(document.getElementById('copy-content'));
        const selection = window.getSelection();
        if (selection.rangeCount > 0) selection.removeAllRanges();
        selection.addRange(range);
        document.execCommand('copy');
        if (successMessage !== false) {
          this.$toast(successMessage);
        }
      }, 100);
    },

    showCommonMask(title, desc) {
      this.commonMask = {title, desc};
    },
    showWechatShareMask() {
      this.wechatShareMask = true;
    },
    onGetCodeClick() {
      if (this.getSMStxt !== "获取验证码") {
        return;
      }

      if (this.loginForm.phone.length != 11) {
        this.$toast('请输入11位手机号');
        return;
      }

      let timer = 60;
      this.getSMStxt = timer + "s 重新获取";
      let interval = setInterval(() => {
        if (timer > 0) {
          timer--;
          this.getSMStxt = timer + "s 重新获取";
        } else {
          this.getSMStxt = "获取验证码"
          clearInterval(interval)
        }
      }, 1000);
      request.request({
        url: "/common/getSmsCode",
        params: {
          phone: this.loginForm.phone,
          type: 'login'
        }
      }).then((data) => {
        console.log(data);
      })
    },
    submitLoginForm() {
      if (!this.agree) {
        this.$toast('需同意用户协议和隐私政策');
        return;
      }
      this.$toast.loading({message: '', duration: 60000, overlay: true});
      request.request({url: '/user/loginByPhone2', data: this.loginForm, method: 'post'}).then((data) => {
        this.$toast.clear();
        this.$cookies.set('authToken', data.token, 86400 * 90);
        storage.setUserInfo(data.userInfo);
        this.$toast('登录成功！');
        let newUrl = location.href.replace('app-token=logout', 'app-token=');
        setTimeout(() => {
          location.replace(newUrl);
        }, 1000)
      }).catch(err => {
        this.$toast.clear();
        setTimeout(() => {
          this.$toast(err.message);
        }, 200);
      })
    },
    fetchServerUserInfo() {
      let requestStatus = 0;
      setTimeout(() => {
        if (requestStatus === 0) {
          this.$toast.loading({message: '', duration: 60000, overlay: true});
        }
      }, 800);
      request.request({
        url: 'user/getInfo'
      }).then(data => {
        requestStatus = 1;
        this.$toast.clear();
        this.loginUser = data;
        storage.setUserInfo(data)
      }).catch((err) => {
        requestStatus = 1;
        this.$toast.clear();
        if (err && err.code > 400 && err.code < 404) {
          this.$toast('登录已过期，请重新登录');
          this.loginUser = null;
          storage.setUserInfo(null);
          this.$cookies.remove('authToken')
        } else {
          this.$dialog.alert({message: err.message ? err.message : '请检查网络！'}).then(() => {
            location.reload()
          })
        }
      })
    },
    doWxShare(title, desc, link, thumb) {
      wx.ready(() => {
        wx.updateAppMessageShareData({
          title: title,
          desc: desc,
          link: link,
          imgUrl: thumb,
          success: () => {

          }
        });

        wx.updateTimelineShareData({
          title: title,
          link: link,
          imgUrl: thumb,
          success: () => {
            // 设置成功
          }
        })
      });
      request.request({
        url: 'wx/config',
        method: 'post',
        data: {
          url: location.href,
        },
      }).then((data) => {
        wx.config({
          debug: false,
          appId: data.appId,
          timestamp: data.timestamp,
          nonceStr: data.nonceStr,
          signature: data.signature,
          jsApiList: ["updateAppMessageShareData", "updateTimelineShareData"] // 必填，需要使用的JS接口列表
        });

      });
    }
  },
  mounted() {
    if (this.authToken) {
      this.fetchServerUserInfo();
    }
  },
  created() {
    if (navigator.userAgent.toLowerCase().indexOf('ipad') > -1
      || navigator.userAgent.toLowerCase().indexOf('mac os') > -1
      || navigator.userAgent.toLowerCase().indexOf('iphone') > -1
      || navigator.userAgent.toLowerCase().indexOf('safari') > -1
    ) {
      this.isIos = true;
      this.isAndroid = false;
    }

    if (
      navigator.userAgent.indexOf('Android') > -1 || navigator.userAgent.indexOf('Adr') > -1 ||
      navigator.userAgent.toLowerCase().indexOf('xiaomi') > -1
    ) {
      this.isAndroid = true;
      this.isIos = false;
    }

    console.log("this.isIos", this.isIos);
    console.log("this.isAndroid", this.isAndroid);

    this.loginUser = storage.getUserInfo();

    if (util.inWechat()) {
      let loadJS = (id, fileUrl) => {
        var scriptTag = document.getElementById(id);
        var oHead = document.getElementsByTagName('HEAD').item(0);
        var oScript = document.createElement("script");
        if (scriptTag) oHead.removeChild(scriptTag);
        oScript.id = id;
        oScript.type = "text/javascript";
        oScript.src = fileUrl;
        oHead.appendChild(oScript);
      }
      loadJS('wxjs', "https://res2.wx.qq.com/open/js/jweixin-1.6.0.js");

      if (this.loginUser) {
        this.wxOpenId = this.loginUser.wxOpenId;
      }

      if (!this.wxOpenId) {
        this.wxOpenId = sessionStorage.getItem('wx_open_id');
      }

      if (!this.wxOpenId) {
        if (this.$route.query && this.$route.query.code) {
          this.$toast.loading({message: '请稍候', duration: 8000, overlay: true});
          request.request({
            url: '/common/getWxgzhInfoByCode?code=' + this.$route.query.code
          }).then(data => {
            this.$toast.clear();
            sessionStorage.setItem('wx_open_id', data.wxOpenId);
            if (this.loginUser) {
              storage.setUserInfo({wxOpenId: data.wxOpenId});
            }
            this.wxOpenId = data.wxOpenId;
          }).catch((err) => {
            this.$toast.clear();
            if (err.code == 401) {
              location.replace(location.href.replace("code=" + this.$route.query.code, "1=1"));
            } else {
              this.$dialog.alert({message: '微信授权失败了，点击确定刷新页面，如有问题请联系Anji技术支持！'}).then(() => {
                location.replace(location.href.replace("code=" + this.$route.query.code, "1=1"));
              })
            }
          });
        } else {
          let url = "https://file.ankichinas.cn/server/api/common/wxgzhAuthBase?url=" + encodeURIComponent(location.href);
          console.log(url);
          location.href = url;
          return;
        }
      }
    }


    let appInner = this.$route.query['app-inner'];
    this.appVersion = this.$route.query['app-version'];
    if (this.appVersion) {
      localStorage.setItem('appVersion', this.appVersion)
    } else {
      if (localStorage.getItem('appVersion')) {
        this.appVersion = localStorage.getItem('appVersion');
      } else {
        this.appVersion = '1.0.0';
      }
    }
    if (appInner) {
      if (appInner === 'yes') {
        this.$cookies.set('appInner', appInner, 3600 * 9);
      } else {
        this.$cookies.remove('appInner');
      }
    }
    this.appInner = this.$cookies.get('appInner') === 'yes';
    this.authToken = this.$cookies.get('authToken');
    sessionStorage.setItem('isAndroid', this.isAndroid ? 'yes' : 'no');
    sessionStorage.setItem('isIos', this.isIos ? 'yes' : 'no');
    let appToken = this.$route.query['app-token'];
    if (appToken === 'logout') {

      /**
       * ignoreLogout 用于忽略app-token=logout，比如登录成功刷新页面时url可能携带 app-token=logout而导致登录态被错误的清理
       */
      if (this.$cookies.get('ignoreLogout') === 'yes') {
        this.$cookies.remove('ignoreLogout')
      } else {
        this.$cookies.remove('authToken');
        this.authToken = '';
        storage.setUserInfo(null)
      }
    } else {
      if (appToken && this.authToken !== appToken) {
        this.authToken = appToken;
      }
    }
    if (this.authToken) {
      this.$cookies.set('authToken', this.authToken, 86400 * 90);
    }

    setTimeout(() => {
      if (akBridge) {
        this.appInner = true;
        akBridge.registerHandler("akUserLoginSuccess", (appToken) => {
          this.$cookies.set('authToken', appToken, 86400 * 90);
          let newUrl = location.href.replace('app-token=logout', 'app-token=');
          setTimeout(() => {
            location.replace(newUrl);
          }, 200)
        });
      }
    }, 200)
  }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.loginDialogFooter {
  margin-bottom: -30px;
  background-image: url('./assets/login-dialog-bg.png');
  background-repeat: repeat-x;
  background-size: auto 100%;
  border-bottom-left-radius: 15px;
  border-bottom-right-radius: 15px;
}

.van-popup {
  background-color: transparent !important;
}
</style>
