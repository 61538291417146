<template>
  <div class="pd-15">
    <div class="lh-50 text-align-center mg-b-20 mg-t-100 bg-app-green c-f bdr-25" @click="onShareClick()">点击弹出分享</div>
    <div class="lh-50 text-align-center mg-b-20 bg-app-orange c-f  bdr-25" @click="onAppClick()">点击获取APP版本号</div>
  </div>
</template>

<script>
  export default {
    name: "testShare",
    mounted() {

    },
    methods: {
      onShareClick() {
        let data = {
          platform: ["QQ", "WEIXIN", "WEIXIN_CIRCLE", "COPY_LINK", "TEAM_POSTER"], //分享面板展示要分享到哪些平台， 后面两个分别是 复制链接 和 海报
          title: "要分享的标题",
          desc: "要分享的描述",
          link: "http://file.ankichias.cn/h5",//链接
          thumb: "https://anki-resource.oss-cn-shenzhen.aliyuncs.com/default/user-avatar.png",//缩略图
          team_info: { //海报相关信息(当platform包含 TEAM_POSTER 的时候有这个字段，没有的话没有这个字段)
            title: "法硕考研上岸合集", //卡牌标题
            origin_price: "99.99", //原价
            team_price: "88.88",  //团购价
            study_users: 9999,  //学习人数
            level: '研究生',  //阶段
            subject: '法律',  //分类
            tags: ['考研', '政治考点', '法硕'],  //标签
            quota: 1,  //剩余名额
            users: 4,  //成团人数
            countDown: 3600,  //倒计时，秒
            members: [ //已购用户，第一个是团长
              {nickname: '张三', avatar: "https://anki-resource.oss-cn-shenzhen.aliyuncs.com/default/user-avatar.png"},
              {nickname: '李四', avatar: "https://anki-resource.oss-cn-shenzhen.aliyuncs.com/default/user-avatar.png"},
              {nickname: '王五', avatar: "https://anki-resource.oss-cn-shenzhen.aliyuncs.com/default/user-avatar.png"},
            ],
            link: "http://file.ankichias.cn/h5",//生成二维码所需文本
          }
        };
        try {
          if (akBridge) {
            akBridge.callHandler("socialShare", JSON.stringify(data));
          } else if (typeof AnkiDroidJS !== 'undefined' ) {
            AnkiDroidJS.socialShare(JSON.stringify(data));
          } else {
            this.$dialog.alert({
              message: '没有JS桥接对象',
            });
          }
        } catch (e) {
          this.$dialog.alert({
            message: '没有JS桥接对象',
          });
        }
      },
      onAppClick() {
        if (akBridge) {
          try {
            let status = 1;
            akBridge.callHandler("getAppVersion", null, (version) => {
              if (version) {
                status = 2;
                this.$dialog.alert({
                  message: '获取到版本号：' + version,
                });
              }
            });

            setTimeout(() => {
              if (status == 1) {
                this.$dialog.alert({
                  message: '没有获取到版本号',
                });
              }
            }, 2000);
          } catch (e) {
            this.$dialog.alert({
              message: '没有获取到版本号',
            });
          }
        } else {
          try {
            let version = AnkiDroidJS.getAppVersion();
            if (version) {
              this.$dialog.alert({
                message: '获取到版本号：' + version,
              });
            } else {
              this.$dialog.alert({
                message: '没有获取到版本号',
              });
            }
          } catch (e) {
            this.$dialog.alert({
              message: '没有获取到版本号',
            });
          }
        }
      }
    },
  }
</script>

<style scoped>

</style>
