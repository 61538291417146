import Vue from 'vue'
import App from './App.vue'
import VueRouter from 'vue-router'
import Vuex from 'vuex'
import Vant from 'vant'
import VueCookies from 'vue-cookies'
import Index from '@/views/index';
import Card from '@/views/card';
import Team from '@/views/team';
import Report from '@/views/report';
import ReportList from '@/views/reportList';
import Vip from '@/views/vip';
import VoiceService from '@/views/voiceService';
import Test from '@/views/test';
import TestShare from '@/views/testShare';
import DownloadApp from '@/views/downloadApp';
import DestroyUser from '@/views/destroyUser';
import Purchased from '@/views/purchased';
import 'vant/lib/index.css'
import './assets/layout.css'
import userCardBrowsingHistory from "@/views/userCardBrowsingHistory.vue";
import WxWebPay from "@/views/wxWebPay.vue";
import Ambassador from "./views/ambassador";
// import Ads from 'vue-google-adsense'


Vue.use(Vuex)
Vue.use(VueRouter)
Vue.use(VueCookies)
Vue.use(Vant);
//google ad
Vue.use(require('vue-script2'))
// Vue.use(Ads.Adsense)
// Vue.use(Ads.InArticleAdsense)
// Vue.use(Ads.InFeedAdsense)

// import VConsole from 'vconsole';
// const vConsole = new VConsole();

const store = new Vuex.Store({
  state: {
    loginUser: null
  },
  mutations: {
    setLoginUser(state, user) {
      state.loginUser = user;
    }
  }
})


//获取原型对象上的push函数
const originalPush = VueRouter.prototype.push
//修改原型对象中的push方法
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
};

let router = new VueRouter({
  mode: 'history',
  base: 'h5',
  routes: [
    {
      path: '/', component: Index, meta: {
        KeepAlive: true
      },
    },
    {
      path: '/card/:cardId', component: Card, meta: {
        KeepAlive: false
      },
    },
    {
      path: '/team/:teamId', component: Team, meta: {
        KeepAlive: false
      },
    },
    {
      path: '/vip', component: Vip, meta: {
        title: '开通超级学霸'
      },
    },
    {
      path: '/s/:code', component: Ambassador, meta: {
        title: '开通超级学霸'
      },
    },
    {
      path: '/voiceService', component: VoiceService, meta: {
        title: '语音服务包'
      },
    },
    {
      path: '/report', component: Report, meta: {
        title: '举报'
      },
    },
    {
      path: '/reportList', component: ReportList, meta: {
        title: '我的举报记录'
      },
    },
    {
      path: '/purchased', component: Purchased, meta: {
        title: '已购'
      },
    },
    {
      path: '/userCardBrowsingHistory', component: userCardBrowsingHistory, meta: {
        title: '浏览记录'
      },
    },
    {
      path: '/test', component: Test, meta: {
        title: 'test'
      },
    }, {
      path: '/testShare', component: TestShare, meta: {
        title: 'test'
      },
    }, {
      path: '/download-app', component: DownloadApp, meta: {},
    }, {
      path: '/destroy-user', component: DestroyUser, meta: {title: '注销'},
    }, {
      path: '/wxWebPay', component: WxWebPay, meta: {title: '微信支付'},
    },
  ]
})

router.beforeEach((to, from, next) => {
  if (window._hmt) {
    if (to.path) {
      window._hmt.push(['_trackPageview', '/#' + to.fullPath])
    }
  }
  /* 路由发生变化修改页面title */
  if (to.meta.title) {
    document.title = to.meta.title
  }
  next()
})


Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app');

var _hmt = _hmt || [];
window._hmt = _hmt; // 修改为window 全局变量
(function () {
  var hm = document.createElement("script");
  hm.src = "https://hm.baidu.com/hm.js?c1efd05db3c9108e017c707e7071ba87";
  var s = document.getElementsByTagName("script")[0];
  s.parentNode.insertBefore(hm, s);
})();
