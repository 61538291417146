<template>
  <div class="justify-content-center h-fill">
    <div class="w-fill bg-f position-relative column" style="min-height: 570px">
      <div class="h-fill">
        <iframe class="hide-scrollbar" :srcdoc="srcdoc" frameborder="0"
                style="width: 100%;border: none;height: 100%;">
        </iframe>
      </div>
      <div class="text-align-center display-flex align-items-center pd-t-8 pd-b-8 cursor-pointer position-relative"
           style="border-top: 1px #f3f3f3 solid" @click="onShowAnswerClick">
        <!-- 预览上一张 -->
        <div class="flex-1 lh-60" @click.stop="previewPreClick">
          <van-icon size="20px" name="arrow-left"/>
        </div>
        <div class="flex-1 lh-60">
          <span>{{ cardStatus === 'qfmt' ? '👀看答案' : '👁️‍🗨️藏起来' }}</span>
        </div>
        <!-- 预览下一张 -->
        <div class="flex-1 lh-60" @click.stop="previewNextClick">
          <van-icon size="20px" name="arrow"/>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import request from '../libs/api.request'
import htmlTpl from '../libs/HtmlTpl'
import util from '../libs/util'

export default {
  name: "cardViewer",
  components: {},
  props: {
    did: {
      type: String,
      default: ''
    },
    cardId: {
      type: String,
      default: ''
    },
    isPreview: {
      type: Boolean,
      default: false
    },
    cardList: {
      type: Array,
      default: () => {
      }
    },
    currentCardIndex: {
      type: Number,
      default: 0
    },
    currentEditCard: {
      type: Object,
      default: () => {
      }
    },
  },
  data() {
    return {
      isShowPreview: this.isPreview,
      srcdoc: '',
      currentSkin: 'sun',
      util: util,
      models: {},
      colConf: {},
      colUsn: 0,
      dconfs: {},
      decks: {},
      crtDue: 0,
      cardStatus: 'qfmt',//qfmt 正面  afmt 背面
      card: {},
      currentModel: {},
      skinClass: '',
      currentIndex: this.currentCardIndex,
    }
  },
  methods: {
    // 上一张
    previewPreClick() {
      if (this.currentIndex === 0) {
        this.$toast('已经是第一张了🫣')
      } else {
        this.currentIndex--
        this.getCurrentFlds()
      }
    },
    // 下一张
    previewNextClick() {
      if (this.currentIndex < this.cardList.length - 1) {
        this.currentIndex++
        this.getCurrentFlds()
      } else {
        setTimeout(() => {
          if (this.currentIndex == this.cardList.length - 1) {
            this.$toast('只能预览这么多了波🫣')
          }
        }, 100)
      }
    },
    //回到正面
    showFront() {
      this.isShowPreview = true
      this.cardStatus = 'qfmt'
      this.rendHtml()
    },
    getDConf(card) {
      return this.dconfs[this.decks[card.did].conf];
    },
    onShowAnswerClick() {
      if (this.cardStatus === 'qfmt') {
        this.cardStatus = 'afmt';
      } else {
        this.cardStatus = 'qfmt';
      }
      this.rendHtml();
    },
    getStudyData() {
      request.request({
        url: '/card/getStudyData2',
        params: {did: this.did, card_id: this.cardId},
      }).then((data) => {
        console.log(data);
        this.models = data.models;
        this.dconfs = data.dconfs;
        this.decks = data.decks;
        this.colConf = data.colConf;
        this.crtDue = data.crtDue;
        this.colUsn = data.colUsn;
        //如果是预览
        if (this.isShowPreview) {
          this.getCurrentFlds()
        }
      }).catch((e) => {
        console.log('错误');
        this.$dialog.alert(e.message, {callback: () => location.reload()})
      })
    },
    findHideIfHas(content, name, value, start) {
      if (start === undefined) {
        start = -1;
      }

      let startPos = content.indexOf('{{^' + name + '}}', start);
      if (startPos > -1) {
        if (value === '') {
          content = content.replace('{{^' + name + '}}', '');
          content = content.replace('{{/' + name + '}}', '');
        } else {
          let endPos = content.indexOf('{{/' + name + '}}', startPos);
          if (endPos > 0) {
            content = content.substr(0, startPos) + content.substr(endPos + ('{{/' + name + '}}').length);
            if (content.indexOf('{{^' + name + '}}', endPos) > -1) {
              content = this.findHideIfHas(content, name, value, endPos)
            }
          }
        }
      }

      return content;
    },
    findHideIfEmpty(content, name, value, start) {
      if (start === undefined) {
        start = -1;
      }

      let startPos = content.indexOf('{{#' + name + '}}', start);
      if (startPos > -1) {
        if (value !== '') {
          content = content.replace('{{#' + name + '}}', '');
          content = content.replace('{{/' + name + '}}', '');
        } else {
          let endPos = content.indexOf('{{/' + name + '}}', startPos);
          if (endPos > 0) {
            content = content.substr(0, startPos) + content.substr(endPos + ('{{/' + name + '}}').length);
            if (content.indexOf('{{#' + name + '}}') > -1) {
              content = this.findHideIfEmpty(content, name, value)
            }
          }
        }
      }

      return content;
    },
    findHint(content, name, value) {
      let id = Math.round(Math.random() * 10000000);
      let hint = `<a class=hint href= "#"
          onclick="this.style.display='none';document.getElementById('hint${id}').style.display='block';return false;">
          显示${name}
          </a >
          <div id="hint${id}" class=hint style="display: none">${value}</div>`;
      content = content.replace("{{hint:" + name + "}}", hint);
      if (content.indexOf("{{hint:" + name + "}}") > -1) {
        content = this.findHint(content, name, value);
      }
      return content;
    },
    findType(content, name, value) {
      if (this.cardStatus === 'qfmt') {
        sessionStorage.setItem("type_input_value", "");
        let inputName = 'input_' + new Date().getTime();
        let replace = `<div style="text-align: center;margin-top: 20px">
<input id="${inputName}_answer" name="answer" style="width: 90%;padding: 10px" placeholder="请输入答案" onkeydown="mOnKeyDown()"/>
<script>
    let ${inputName}_answer = document.getElementById('${inputName}_answer');
    ${inputName}_answer.oninput = function(){
      sessionStorage.setItem("type_input_value",${inputName}_answer.value);
    }
<\/script>
`;
        content = content.replace("{{type:" + name + "}}", replace);
      } else {
        let inputValue = sessionStorage.getItem("type_input_value");
        if (inputValue === undefined || inputValue === null || inputValue === "") {
          inputValue = "&nbsp;&nbsp;&nbsp;";
        }
        let replace = "<div style='margin-top: 20px'><span style='padding: 5px;background-color: " + (inputValue === value ? '#28BEA0' : '#FF0000') + "'>" + inputValue + "</span></div>";
        replace += "<div style='margin: 10px'>↓</div>";
        replace += "<div><span style='padding: 5px;background-color: #f3f3f3'> " + value + "</span></div>";
        content = content.replace("{{type:" + name + "}}", replace);
      }
      return content;
    },
    showCard() {
      this.currentModel = this.models[this.card.mid];
      this.cardStatus = 'qfmt';
      this.rendHtml();
    },
    findMedia(content) {
      let arr = content.match(/\[sound:([\w\S\s]+?)\]/gi);
      if (arr && arr.length > 0) {
        arr.forEach((v, k) => {
          let src = v.substring(7, v.length - 1);
          let id = Math.round(Math.random() * 10000000);
          let replace;
          if (k === 0) {
            replace = `<i class='iconfont icon-play'  onclick='mPlayAudio("${id}")' style='padding: 5px;font-size: 20px;cursor: pointer'><audio autoplay id='${id}' class='ak-play-audio'><source src="/${src}"></audio></i>`;
          } else {
            replace = `<i class='iconfont icon-play'  onclick='mPlayAudio("${id}")' style='padding: 5px;font-size: 20px;cursor: pointer'><audio class='ak-play-audio' id='${id}'><source src="/${src}"></audio></i>`;
          }
          content = content.replace(v, replace);
        });
      }
      return content;
    },
    rendHtml() {
      let html = htmlTpl;
      let tmplsIndex = Math.min(this.card.ord ? parseInt(this.card.ord) : 0, this.currentModel.tmpls.length - 1);
      let content = this.currentModel.tmpls[tmplsIndex][this.cardStatus];
      if (this.cardStatus === 'afmt') {
        content = content.split("{{FrontSide}}").join(this.currentModel.tmpls[tmplsIndex]['qfmt']);
        html = html.replace("::class::", 'ck-back');
      } else {
        html = html.replace("::class::", 'ck-front');
      }
      content = content.replace(new RegExp("{{=<% %>=}}", "gm"), "");
      content = content.replace(new RegExp("<%", "gm"), "{{");
      content = content.replace(new RegExp("%>", "gm"), "}}");
      let hasCloze = content.indexOf('{{cloze') > -1;//  挖空模板
      let needFindType = content.indexOf('{{type:') > -1; //   输入答案模板
      let needFindHint = content.indexOf('{{hint:') > -1; //   点击显示模板
      this.currentModel.flds.forEach((v, k) => {
        content = content.replace(new RegExp("{{furigana:" + v.name + "}}", "gm"), this.card.flds[k]);
        content = content.replace(new RegExp("{{kana:" + v.name + "}}", "gm"), this.card.flds[k]);
        content = content.replace(new RegExp("{{" + v.name + "}}", "gm"), this.card.flds[k]);
        content = content.replace(new RegExp("{{ " + v.name + " }}", "gm"), this.card.flds[k]);
        content = content.replace(new RegExp("{{edit:" + v.name + "}}", "gm"), this.card.flds[k]);
        if (hasCloze) {
          if (v.name !== 'Text' && v.name.indexOf('Text') === 0) {
            let txtOrd = parseInt(v.name.replace('Text', ''));
            content = content.replace(new RegExp("{{cloze:" + v.name + "}}", "gm"), txtOrd === parseInt(this.card.ord) + 1 ? this.card.flds[k] : '');
          } else {
            content = content.replace(new RegExp("{{cloze:" + v.name + "}}", "gm"), this.card.flds[k]);
          }
        }
        content = this.findHideIfEmpty(content, v.name, this.card.flds[k]);
        content = this.findHideIfHas(content, v.name, this.card.flds[k]);
        if (needFindHint) {
          content = this.findHint(content, v.name, this.card.flds[k]);
        }
        if (needFindType) {
          content = this.findType(content, v.name, this.card.flds[k]);
        }
      });
      content = content.replace(new RegExp("{{Tags}}", "gm"), this.card.tags);
      content = content.replace(new RegExp("{{Deck}}", "gm"), this.decks[this.card.did].name.split('::')[0]);
      content = content.replace(new RegExp("{{Subdeck}}", "gm"), this.decks[this.card.did].name.split('::').pop());
      content = this.findHideIfEmpty(content, 'Tags', this.card.tags);
      content = this.findHideIfHas(content, 'Tags', this.card.tags);
      content = this.findMedia(content);
      let arr = content.match(/\{\{c([\w\W]*?)\}\}/gi);
      if (arr) {
        arr.forEach((v, k) => {
          let newArr = v.substr(3, v.length - 5).split('::');
          if (newArr.length === 2) {
            if (this.cardStatus === 'afmt') {
              if (!hasCloze || parseInt(newArr[0]) === (parseInt(this.card.ord) + 1)) {
                if (v.indexOf("\\") === -1) {
                  content = content.replace(v, '<span class=cloze>' + newArr[1] + '</span>')
                } else {
                  content = content.replace(v, newArr[1])
                }
              } else {
                content = content.replace(v, newArr[1])
              }
            } else {
              if (!hasCloze || parseInt(newArr[0]) === (parseInt(this.card.ord) + 1)) {
                if (v.indexOf("\\") === -1) {
                  content = content.replace(v, '<span class=cloze>[...]</span>')
                } else {
                  content = content.replace(v, '[...]')
                }
              } else {
                content = content.replace(v, newArr[1])
              }
            }
          }
        })
      }

      let splitContent = html.split("{{content}}");
      html = splitContent[0] + content + splitContent[1];

      let style = this.currentModel['css'];
      let splitStyle = html.split("{{style}}");
      html = splitStyle[0] + style + splitStyle[1];
      html = html.replace("{{script}}", "");
      html = html.replace(new RegExp("&nbsp;", "gm"), " ");
      this.srcdoc = html;
    },
    constrainedIvl(ivl, ivlFct, maxIvl, prev) {
      ivl = Math.max(parseInt(ivl * ivlFct), parseFloat(prev) + 1);
      ivl = Math.max(parseInt(ivl), 1);
      return Math.min(ivl, maxIvl);
    },
    async getCurrentFlds() {
      this.card = this.cardList[this.currentIndex];
      this.showCard();
    },
    closeReview() {
      this.$emit('previewClose');
    },
  },
  mounted() {
    this.getStudyData();
    if (this.isShowPreview) {
      this.card = this.cardList[0];
    }
  },
  created() {
    console.log('card viewer created>>>>>>>>');
    /** 阻止页面滚动 */
    document.body.style.cssText = 'overflow-y: hidden; height: 100%;';
  },
  destroyed() {
    /** 取消阻止页面滚动 */
    document.body.style.cssText = 'overflow-y: auto; height: 100%;'
  }
}
</script>

<style scoped>
.button {
  cursor: pointer;
  flex: 1;
  background-color: #F7F7F7;
  border: 2px solid #F7F7F7;
  margin: 8px;
  border-radius: 10px;
}

.button .time {
  color: #999999;
}

.prveBackground {
  background: rgba(0, 0, 0, .5);
}

.normalBackground {
  background: rgba(255, 255, 255, .9);
}
</style>
