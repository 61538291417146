let html = `
<!doctype html>
<html class="mobile android linux js">
    <head>
        <title>AnjiMemory</title>
        <meta charset="utf-8">
        <link rel="stylesheet" type="text/css" href="/h5/static/card-viewer-iconfront/iconfont.css" /> 
        <link rel="stylesheet" type="text/css" href="/h5/static/css/flashcard.css"/>
        <link rel="stylesheet" type="text/css" href="/h5/static/css/anki-extend.css"/>
        <link rel="stylesheet" type="text/css" href="/h5/static/css/layout.css"/>
        <link rel="stylesheet" type="text/css" href="/h5/static/js/mathjax/mathjax.css"/>
        <script src="/h5/static/js/anki-extend.js"></script>
        
        <script src="/h5/static/js/jquery.min.js"></script>
        <script src="/h5/static/js/mathjax/conf.js"></script>
       <script>
        MathJax = {
          tex: {
            inlineMath:[['$', '$'], ['\\\\(', '\\\\)']]
          }
        };
       
        </script>
        
        <script type="text/javascript" id="MathJax-script" src="/h5/static/js/mathjax/tex-chtml.js"></script>
        <style>
          img{
            max-width: 100% !important;
          }
          .cloze {
            font-weight: bold;
            color: blue;
          }
          .container {
              width:auto
              min-width:auto 
          }
          li {
            word-break: break-all;
          }
        </style>
        <style>
        {{style}}
        </style>
    </head>
    <body class="card ck-content ::class::">
        <div id="content">
        {{content}}
        </div>
    </body>
    <script>
      function mPlayAudio(id){
        console.log(id);
        document.getElementById(id).play();
      }
      
      function mOnKeyDown(){
        if(event.keyCode===13){
          console.log("onEnterDown");
          window.parent.onEnterDown();
        }
        
        return false;
      }
      
      
      let audioList = document.getElementsByClassName("ak-play-audio");
      if(audioList.length > 1){
        for(let i=0;i<audioList.length;i++){
          let index = i+1;
          audioList[i].addEventListener('ended', function() {
            if(i+1 < audioList.length){
              console.log(audioList[index]);
              audioList[index].play();
            }
          })
        }
        
      }
    </script>
    <script>
      {{script}}
    </script>
    <style>
    figure img{
      user-select:none;
    }
    </style>
</html>
`;

export default html;
