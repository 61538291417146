<template>
  <div class="position-relative">
    <div class="position-fixed bg-f w-fill t-0 pd-t-15 pd-b-15 pd-l-15 pd-r-15">
      <div v-if="$parent.appInner" class="c-app-green float-left" @click="onBackClick">
        首页
      </div>
      <div v-else @click="onBackClick" class="float-left">
        <van-icon name="arrow-left"/>
      </div>
      <div class="float-right mg-r-25 fs-12 c-9">
        <div>卡牌浏览记录</div>
      </div>
    </div>
    <div class="mg-t-50">
      <div v-if="list.length>0" class="bg-e">
        <div v-for="(item,index) in list" class="pd-5">
          <card-item class="bg-f bdr-6" :card="item"></card-item>
        </div>
      </div>
      <div class="text-align-center pd-15" v-else>
        {{ text }}
      </div>
    </div>
  </div>
</template>

<script>
import request from "@/libs/api.request";
import util from "@/libs/util";
import CardItem from "@/components/cardItem";

export default {
  name: "userCardBrowsingHistory",
  components: {CardItem},
  data() {
    return {
      showDialog: false,
      list: [],
      text: '',
    }
  },
  props: [],
  methods: {
    getUserCardBrowsingHistory() {
      request.request({
        url: '/user/getUserCardBrowsingHistory',
        data: {},
        method: 'post'
      }).then((data) => {
        this.list = data;
        if (util.isEmpty(data)) {
          this.text = '暂无记录';
        }
      }).catch((err) => {
        console.log(err);
      })
    },
    onBackClick() {
      this.$router.push({path: '/'});
    },
    onItemClick() {
      this.$router.push({path: '/card/' + this.card.cardId})
    }
  },
  mounted() {
    this.getUserCardBrowsingHistory();
  }
}
</script>

<style scoped>
#app {
  background-color: #eeeeee;
}
</style>
