<template>
  <div>
    <div class="pd-20">
      <div class="justify-content-space-between" @click="onOptionClick">
        <div>举报原因：</div>
        <div v-if="selected > -1">{{ options[selected] }}</div>
        <div v-else class="c-9">请选择</div>
      </div>
      <div class="mg-t-20 mg-b-20 h-1 bg-f3f"></div>
      举报描述：
      <div class="pd-2 mg-t-20 border-1 bdc-c bdr-5">
        <van-field
            v-model="message"
            rows="1"
            :autosize="{minHeight:100}"
            type="textarea"
            placeholder="请输入举报描述（选填）"
        />
      </div>

      <van-popup v-model="showOptions" position="bottom">
        <van-picker @confirm="onConfirm"
                    @cancel="onCancel" show-toolbar :columns="options"/>
      </van-popup>
      <div class="mg-t-20 bg-app-green text-align-center lh-44 c-f bdr-22 flex-1" @click="onSubmitClick">提交</div>
      <div class="justify-content-center mg-t-30 c-4">
        <span @click="onReportListClick" class="fs-14">我的提交记录和回复&gt;&gt;</span>
      </div>
    </div>
    <div class="position-fixed w-fill b-0 mg-r-20" v-if="orderId">
      <div class="text-align-center c-app-orange mg-b-10 lh-44 bdc-d bdr-22" @click="showRefundPopup">
        申请退款
      </div>
    </div>
    <van-popup v-model="showRefund" round closeable style="width: 75%">
      <div class="bg-f pd-20">
        <div class="c-a">退款申请</div>
        <div class="fs-18 pd-2 mg-t-15 mg-b-20 border-1 bdc-c bdr-5">
          <van-field
              class="fs-16"
              type="textarea"
              v-model="applyRemark"
              placeholder="申请退款理由"
              rows="5"
              border
              autosize
              maxlength="100"
              show-word-limit
          />
        </div>
        <div class="pd-8 pd-r-20 pd-l-20 c-app-orange text-align-center border-1 bdr-22"
             @click="onApplyRefundClick">
          提交申请
        </div>
      </div>
    </van-popup>
  </div>
</template>

<script>

import request from '../libs/api.request'

export default {
  name: "report",
  data() {
    return {
      message: '',
      selected: -1,
      options: ['卡牌盗卖', '质量问题', '显示问题', '其他'],
      showOptions: false,
      showRefund: false,
      cardId: '',
      orderId: '',
      applyRemark: '',
    }
  },
  methods: {
    onConfirm(e) {
      this.selected = this.options.indexOf(e);
      this.showOptions = false;
    },
    onCancel() {
      this.showOptions = false;
    },
    onSubmitClick() {
      if (this.selected === -1) {
        this.$toast('请选择举报原因');
        return;
      }
      request.request({
        url: '/card/report',
        data: {cardId: this.cardId, message: this.message, selected: this.selected},
        method: 'post'
      }).then((data) => {
        this.$toast({message: '提交成功！', type: 'success'});
        setTimeout(() => {
          this.$router.replace({path: 'reportList'});
        }, 800);
      }).catch(e => {
        this.$toast(e);
      });
    },
    onReportListClick() {
      this.$router.push({path: 'reportList'});
    },
    onOptionClick(e) {
      this.showOptions = true;
    },
    checkRefund() {
      request.request({
        url: '/user/checkRefund',
        data: {cardId: this.cardId},
        method: 'post'
      }).then((data) => {
        this.orderId = data.orderId;
      }).catch(e => {
        this.$toast(e);
      });
    },
    showRefundPopup() {
      this.showRefund = true;
    },
    onApplyRefundClick() {
      if (!this.applyRemark) {
        this.$toast('请填写退款理由');
        return;
      }
      request.request({
        url: '/user/addUserApplyRefund',
        data: {orderId: this.orderId, applyRemark: this.applyRemark},
        method: 'post'
      }).then((data) => {
        this.$toast({message: '提交成功！', type: 'success'});
        setTimeout(() => {
          location.reload();
        }, 800);
      }).catch(e => {
        this.$toast(e);
      });
    },
  },
  mounted() {
    this.cardId = this.$route.query.cardId;
    this.checkRefund();
  }
}
</script>

<style scoped>

</style>
