<template>
  <div class="pd-l-10 pd-b-10 pd-t-10 bg-f" @click="onItemClick">
    <div class="fs-16 text-ellipsis-3 h-55">
      <span v-if="card.mark" class="pd-l-6 pd-r-6 display-inline-block lh-18 h-18 bdr-2 fs-10 mg-r-5"
            style="background: linear-gradient(51deg, #262626 0%, #545454 100%);color: #FFC680;vertical-align: middle;"
      >{{ card.mark }}</span>
      <span class="text-strong" style="vertical-align: middle;">{{ card.title }}</span>
    </div>
    <div class="justify-content-space-between mg-t-6 fs-14 align-items-center mg-r-10">
      <!--      <div class="c-app-orange" v-if="card.price > 0">
              <template v-if="isIos && appInner">{{ card.price / 100 }}学习币</template>
              <template v-else>￥{{ card.price / 100 }}</template>
            </div>
            <div class="c-app-orange" v-else>免费</div>-->
      <div v-if="card.evaluateUserCount >= 5" class="align-items-center">
        <div class="c-app-orange mg-t-2 mg-l-5">{{ card.score }} 评分</div>
      </div>
      <div v-else class="align-items-center">
        评分累积中
      </div>
      <div class="fs-12 c-app-green text-align-right text-strong">
        {{ card.levelName }} / {{ card.subjectName }}
      </div>
    </div>
    <!--    <div class="fs-10 justify-content-space-between align-items-center c-9  mg-r-10">
          <div>{{ card.hotValue }}</div>
          &lt;!&ndash;<div>{{card.evaluateUserCount}}人评分</div>&ndash;&gt;
          <div v-if="card.evaluateUserCount >= 5" class="align-items-center">
            <div class="mg-t-2 mg-l-5">{{ card.score }} 评分</div>
          </div>
          <div v-else class="align-items-center">
            评分累积中
          </div>
        </div>-->
  </div>
</template>

<script>
import request from "@/libs/api.request";

export default {
  name: "cardItem",
  data() {
    return {
      appInner: false,
      isIos: false,
    }
  },
  props: ['card'],
  methods: {
    onItemClick() {
      this.onAddUserCardBrowsingHistoryClick();
      this.$router.push({path: '/card/' + this.card.cardId})
    },
    onAddUserCardBrowsingHistoryClick() {
      let that = this;
      request.request({
        url: '/user/saveUserCardBrowsingHistory',
        data: {card_id: that.card.cardId},
        method: 'post'
      }).then((data) => {
        console.log(data);
      }).catch((err) => {
        console.log(err);
      })
    },
  },
  mounted() {
    this.appInner = this.$cookies.get('appInner') === 'yes';
    this.isIos = sessionStorage.getItem('isIos') === 'yes';
  }
}
</script>

<style scoped>

</style>
