<template>
  <div class="h-fill bg-e8e">
    <div class="position-relative pd-0 mg-0">
      <div>
        <div class="pd-15 bg-e8e fs-12">
          <div class="h-50 justify-content-center align-items-center fs-22 c-f"
               style="background: linear-gradient(233deg, #30Cfbf 0%, #28BEA0 100%);border-radius: 10px 10px 0px 0px;">
            购买在线朗读次数
          </div>
          <div class="bg-f pd-15" style="border-radius: 0px 0px 10px 10px;">
            <div class="pd-b-10">
              在线朗读费用较高，我们无法免费提供，需用户自行购买充值朗读次数
            </div>
            <div class="display-flex pd-b-10">
              <div v-for="item,index in pkg"
                   class="flex-1 border-3 bdr-10 shadow-2 align-items-center justify-content-center h-110 mg-l-5 mg-r-5 position-relative"
                   :class="index == selectPkg?'bdc-app-orange':'bdc-f'" @click="onPkgClick(index)">
                <div class="column align-items-center">
                  <div class="c-app-orange fs-16 text-strong">￥ <span class="fs-26">{{item.money}}</span></div>
                  <div v-if="item.originMoney" class=" text-delete c-9">
                    原价￥<span class="fs-12">{{item.originMoney}}</span></div>
                  <div>{{item.desc}}</div>
                </div>
                <div v-if="item.originMoney"
                     class="position-absolute t-0 r-0 fs-12 bg-app-orange c-f pd-2 pd-l-5 pd-r-5"
                     style="right: -3px;top:-3px;border-bottom-left-radius: 10px;border-top-right-radius: 10px"
                >限时特惠
                </div>
              </div>
            </div>
            <div class="text-align-center" v-if="pkg.length>1 && pkg[0].activityEndAt">限时特惠，{{pkg[0].activityEndAt}}结束
            </div>
          </div>
        </div>
      </div>
      <div class="">
        <div @click="onBuyClick"
             class="mg-l-20 mg-r-20 mg-t-6 mg-b-6 pd-10 bg-app-green c-f text-align-center lh-30 fs-20 bdr-25">
          支付宝支付
        </div>
      </div>
      <div class="c-9 pd-10 text-align-center fs-14">剩余在线朗读次数：{{total}}</div>

    </div>

    <van-dialog v-model="showPayResult" title="支付提示" show-cancel-button confirmButtonText="已支付"
                cancelButtonText="未支付" @confirm="onPayConfirm" @cancel="onPayCancel">
      <div class="mg-20">
        <div class="text-align-center">是否已支付完成？</div>
        <div v-if="$parent.appInner" class="c-app-red fs-14 mg-t-20">若无法调起支付宝，请复制链接到浏览器打开，<span class="c-app-blue"
                                                                                                @click="onCopyPayUrlClick">点我复制链接</span>
        </div>
      </div>
    </van-dialog>
  </div>
</template>

<script>
  import request from '../libs/api.request'
  import util from '../libs/util'

  export default {
    name: "vip",
    data() {
      return {
        total: '-',
        pkg: [],
        copyPayUrl: '',
        selectPkg: 0,
        showPayResult: false,
        ordering: false
      }
    },
    methods: {
      onPayConfirm() {
        location.reload();
      },
      onPayCancel() {
        location.reload();
      },
      onCopyPayUrlClick() {
        this.$parent.execCopy(this.copyPayUrl, '链接已复制请前往手机浏览器粘贴支付');
      },
      onPkgClick(index) {
        this.selectPkg = index;
      },
      onBuyClick() {
        if (!this.$parent.loginUser) {
          if (this.$parent.isIos) {
            akBridge.callHandler("akUserLogin");
            return;
            // }
          } else {
            this.$toast('请先登录');
            return;
          }
        }
        if (this.ordering) {
          return;
        }

        this.ordering = true;
        if (false) {
          // if (this.$parent.isIos) {
          if (!util.compareVersionIfMax(this.$parent.appVersion, '1.8.9')) {
            this.$dialog.alert({
              message: '请前往appStore更新版本！',
            });
            return;
          }
          this.$toast.loading({message: '下单中', duration: 8000, overlay: true});
          setTimeout(() => {
            this.ordering = false;
          }, 8000);
          akBridge.callHandler("akBuyVIP", this.pkg[this.selectPkg].id)
        } else {
          this.$toast.loading({message: '下单中', duration: 8000, overlay: true});
          request.request({
            url: '/user/voiceOrder',
            data: {
              id: this.pkg[this.selectPkg].id,
              returnUrl: location.href
            },
            method: 'post'
          }).then((data) => {
            var a = null;
            setTimeout(() => {
              if (a !== null) {
                document.body.removeChild(a)
              }
              this.showPayResult = true;
            }, 500);
            this.$toast.clear();
            this.ordering = false;
            this.copyPayUrl = data.payUrl;
            if (this.$parent.isIos) {
              if (!this.$parent.appInner) {
                location.assign(data.payUrl);
              }
            } else {
              a = document.createElement("a");
              a.href = data.payUrl;
              a.target = '_new';
              document.body.appendChild(a);
              a.click();
            }
          }).catch((err) => {
            this.$toast.clear();
            this.ordering = false;
            this.$toast(err.message)
          })
        }
      }
    },
    mounted() {
      request.request({
        url: 'user/voicePkg',
        params: {
          appVersion: this.$parent.appVersion,
          // ios: this.$parent.isIos ? 1 : 0
          ios: 0
        }
      }).then(data => {
        this.pkg = data.pkg;
        if (this.pkg.length > 0) {

        }
        this.total = data.total;
      }).catch((err, code) => {
        this.$toast(err)
      })
    }
  }
</script>

<style scoped>

</style>
