<template>
  <van-popup v-model="showUserLabelDialog"
             position="top">
    <div class="bg-f h-fill pd-l-10 pd-r-10 position-relative">
      <div class="position-fixed bg-f w-fill t-0 r-0">
        <div class="fs-16 pd-t-15 text-align-center h-30">选择喜欢内容</div>
        <van-icon class="position-absolute r-15 t-16 fs-20" @click="onClose" name="cross"/>
      </div>
      <div class="pd-15 mg-b-42 mg-t-42">
        <div v-for="(item,index) in levelList">
          <div class="pd-b-20 fs-14 text-strong">{{ item.text }}</div>
          <div class="listbox mg-l-10">
            <div class="list display-inline-block text-align-center fs-14" v-for="(c,i) of item.children"
                 :key="item.level+'-'+c.subjectId"
                 :class="{checked:checkArr.includes(item.level+'-'+c.subjectId)}"
                 @click="checkedBox(item.level+'-'+c.subjectId)">{{ c.subjectName }}
            </div>
          </div>
        </div>
      </div>
      <van-button class="position-fixed b-0 r-0" type="primary" block @click="onItemClick">
        确定(已选{{ checkCount }}个)
      </van-button>
    </div>
  </van-popup>
</template>

<script>
import request from "@/libs/api.request";
import util from "@/libs/util";

export default {
  name: "userLabel",
  data() {
    return {
      showUserLabelDialog: false,
      level: '',
      levelList: [],
      checkArr: [],
      checkCount: 0,
    }
  },
  props: [],
  methods: {
    onClose() {
      this.showUserLabelDialog = false;
      let timestamp = Date.parse(new Date()) / 1000;
      localStorage.setItem('user_label_popup', timestamp);
    },
    onChangeShow() {
      this.showUserLabelDialog = true;
    },
    // 点击多选标签
    checkedBox(i) {
      let that = this;
      if (this.checkArr.includes(i)) {
        //includes()方法判断是否包含某一元素,返回true或false表示是否包含元素，对NaN一样有效
        //filter()方法用于把Array的某些元素过滤掉，filter()把传入的函数依次作用于每个元素，然后根据返回值是true还是false决定保留还是丢弃该元素：生成新的数组
        this.checkArr = this.checkArr.filter(function (ele) {
          return ele != i;
        });
      } else {
        this.checkArr.push(i);
      }
      this.checkCount = this.checkArr.length;
    },
    getUserLabel() {
      request.request({
        url: '/user/getUserLabel',
        data: {},
        method: 'post'
      }).then((data) => {
        console.log(data);
        if (!data) {
          let timestamp = Date.parse(new Date()) / 1000;
          let tc = localStorage.getItem('user_label_popup');
          if ((timestamp - tc) > 86400) {
            this.showUserLabelDialog = true;
          }
        }
        if (!util.isEmpty(data.interests)) {
          this.checkArr = data.interests;
          this.checkCount = data.interests.length;
        }
      }).catch((err) => {
        console.log(err);
      })
    },
    getSubjectByLevel() {
      request.request({
        url: '/subject/getSubjectByLevel',
        data: {},
        method: 'post'
      }).then((data) => {
        console.log(data);
        this.levelList = data;
      }).catch((err) => {
        console.log(err);
      })
    },
    onItemClick() {
      console.log(this.checkArr);
      let that = this;
      request.request({
        url: '/user/saveUserLabel',
        data: {interests: JSON.stringify(that.checkArr)},
        method: 'post'
      }).then((data) => {
        this.$toast('设置成功');
        setTimeout(function () {
          location.reload();
        }, 800);
      }).catch((err) => {
        if (err.code === 401 || err.code === 403) {
          this.$parent.login();
          return;
        }
        this.$toast(err.message)
      })
    }
  },
  mounted() {
    this.getSubjectByLevel();
    this.getUserLabel();
  }
}
</script>

<style scoped>
.listBox {
  display: flex;
  flex-wrap: wrap;
}

.list {
  border: 1px solid #e2e5ea;
  padding: 8px 12px;
  border-radius: 100px;
  margin-right: 15px;
  margin-bottom: 15px;
  cursor: pointer;
}

.checked {
  color: #FFFFFF;
  background: #28BEA0;
  border: 1px solid #28BEA0;
}

.van-button--primary {
  color: #fff;
  background-color: #28BEA0;
  border: 1px solid #28BEA0;
}
</style>
