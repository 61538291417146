<template>
  <div class="h-fill">
    <h3 class="mg-t-30 text-align-center">{{message}}</h3>
  </div>
</template>

<script>

import request from '../libs/api.request'
import util from '../libs/util'

export default {
  name: "wxWebPay",
  methods: {},
  data() {
    return {
      message: '请稍等'
    }
  },
  mounted() {
    if (!util.inWechat()) {
      this.$dialog.alert({
        message: '请使用微信扫码支付！',
      });
      return;
    }


    if (this.$route.query && this.$route.query.keys) {
      let keys = decodeURIComponent(this.$route.query.keys);
      var interval = setInterval(() => {
        let wxOpenid = this.$parent.wxOpenId;
        console.log(wxOpenid);
        if (!wxOpenid) {
          this.message = "请稍等，正在跳转授权...";
        } else {
          clearInterval(interval);
          this.message = "请稍等，正在调起支付...";
          request.request({
            url: '/user/noLoginVipOrder',
            data: {keys: keys, wxOpenid: wxOpenid},
            method: 'post',
          }).then((data) => {
            WeixinJSBridge.invoke('getBrandWCPayRequest', data.payData, (res) => {
              if (res.err_msg === "get_brand_wcpay_request:ok") {
                this.$toast.loading({message: '正在查询支付结果', duration: 60000, overlay: true});
                setTimeout(() => {
                  this.$dialog.alert({
                    message: '支付成功！',
                  }).then(() => {
                    this.message = "支付成功！网页如无反应请刷新页面查看结果";
                    // location.replace('https://file.ankichinas.cn/h5/');
                  });
                }, 3000);
              }
            });
          }).catch((e) => {
            //this.$toast(e.message);
            console.log(e)
          })
        }
      }, 1000);
    }
  },
}
</script>

<style scoped>

</style>
