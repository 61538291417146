<template>
  <div>
    <!--<button type="info" @click="onclick" class="mg-30 pd-10 ">打开scheme://ankichina/home</button>-->
    <!--<button type="info" @click="onWxpayClick" class="mg-30 pd-10 ">{{payMsg}}</button>-->
    {{txt}}
    <div></div>
    <button @click="onCopyClick">复制</button>
  </div>
</template>

<script>

  import util from '../libs/util'
  import request from '../libs/api.request'

  export default {
    name: "test",
    data() {
      return {
        txt: '',
      }
    },
    methods: {
      onCopyClick: function () {
        this.$parent.execCopy(this.txt, '复制成功');
      }
    },
    mounted() {
      this.txt = this.$parent.wxOpenId;
    }
  }
</script>

<style scoped>

</style>
