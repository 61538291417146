/**
 * 判断是否是空
 * @param obj
 * @returns {boolean}
 */
let isEmpty = function (obj) {
  if (obj === null) return true;
  if (typeof obj === 'undefined') {
    return true;
  }

  if (typeof obj === "function") {
    return false;
  }

  if (typeof obj === 'string') {
    if (obj === "") {
      return true;
    }
    let reg = new RegExp("^([ ]+)|([　]+)$");
    return reg.test(obj);
  }

  if (obj instanceof Array && obj.length === 0) {
    return true;
  }

  return JSON.stringify(obj) == "{}";
};

let inWechat = function () {
  let ua = navigator.userAgent.toLowerCase();
  return ua.match(/MicroMessenger/i) == "micromessenger";
}

let inWechatPC = function () {
  if (inWechat()) {
    let p = navigator.platform;
    let win = p.indexOf("Win") == 0;
    let mac = p.indexOf("Mac") == 0;
    return win || mac
  }
  return false;
}

function inQQ() {
  return navigator.userAgent.indexOf('QQ/') > 0;
}

/*
 * 版本号比较方法
 * 传入两个字符串，当前版本号：curV；比较版本号：reqV
 * 调用方法举例：compare("1.1","1.2")，将返回false (第一个参数大返回true)
 */
function compareVersionIfMax(curV, reqV) {
  if (curV && reqV) {
    //将两个版本号拆成数字
    let arr1 = curV.split('.'),
      arr2 = reqV.split('.');
    let minLength = Math.min(arr1.length, arr2.length),
      position = 0,
      diff = 0;
    //依次比较版本号每一位大小，当对比得出结果后跳出循环（后文有简单介绍）
    while (position < minLength && ((diff = parseInt(arr1[position]) - parseInt(arr2[position])) == 0)) {
      position++;
    }
    diff = (diff != 0) ? diff : (arr1.length - arr2.length);
    //若curV大于reqV，则返回true
    return diff > 0;
  } else {
    //输入为空
    console.log("版本号不能为空");
    return false;
  }
}

//生成从minNum到maxNum的随机数
function randomNum(minNum, maxNum) {
  switch (arguments.length) {
    case 1:
      return parseInt(Math.random() * minNum + 1, 10);
      break;
    case 2:
      return parseInt(Math.random() * (maxNum - minNum + 1) + minNum, 10);
      break;
    default:
      return 0;
      break;
  }
}


export default {isEmpty, inWechat, inQQ, inWechatPC, compareVersionIfMax, randomNum}
