<template>
  <div>
    <van-collapse v-model="activeItem" :border="false">
      <div v-for="(deck,index) in decks" :key="deck.id">
        <div class="position-relative">
          <van-collapse-item v-if="deck.decks" :name="deck.id" :border="false">
            <div :style="'width:'+width+'px'" slot="title">
              <div :title="index.length>10?index:''" class="fs-14 c-5 text-ellipsis-1"
                   :class="level >= 0?'text-strong':''">
                <span :class="{'text-strong':activedid == deck.id}">{{ index }}</span>
              </div>
            </div>
            <deck-item class="mg-l-15" :activedid="activedid" :open="open"
                       :decks="deck.decks" :level="level + 1">
            </deck-item>
          </van-collapse-item>
          <div style="cursor: pointer;" class="mg-l-10" v-else>
            <div :style="'width:'+width+'px'" class="h-48 lh-48">
              <div class="fs-14 c-7 text-ellipsis-1">
                <span :class="{'text-strong':activedid == deck.id}">{{ index }}</span>
              </div>
            </div>
          </div>
          <div class="position-absolute t-12 r-26 c-b fs-12 pd-t-2">
            <div>{{ deck.totalCard }}</div>
          </div>
        </div>
      </div>
    </van-collapse>
  </div>
</template>

<script>
export default {
  data() {
    return {
      width: 230,
      activeItem: []
    }
  },
  props: {
    decks: {
      type: Object,
      default: null
    },
    level: {
      type: Number,
      default: 0
    },
    activedid: {
      type: String,
      default: ''
    },
    open: {
      type: Boolean,
      default: true
    }
  },
  name: "deckItem",
  methods: {},
  mounted() {
    if (this.open) {
      this.activeItem = [];
      if (this.decks && this.level < 2) {
        for (let k in this.decks) {
          this.activeItem.push(this.decks[k].id);
        }
      }
    }
  }
}
</script>

<style>
.van-cell {
  padding: 10px 10px !important;
}

.van-collapse-item__content {
  padding: 12px 0px !important;
  color: #969799;
  font-size: 14px;
  line-height: 1.5;
  background-color: #fff;
}
</style>
