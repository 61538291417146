import util from './util'

let getUserInfo = () => {
  let userInfo = localStorage.getItem('userInfo');
  if (userInfo) {
    return JSON.parse(userInfo);
  }

  return null;
}

let setUserInfo = (info) => {
  if (!util.isEmpty(info)) {
    let userInfo = getUserInfo();
    if (!userInfo) {
      userInfo = info;
    } else {
      for (let key in info) {
        userInfo[key] = info[key];
      }
    }

    localStorage.setItem('userInfo', JSON.stringify(userInfo));
  } else {
    localStorage.removeItem('userInfo');
  }
}

export default {
  getUserInfo, setUserInfo
}
